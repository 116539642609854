import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import SnackBar from "../SnackBar/SnackBar";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import { useEffect } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Filter, Trash2, Plus, Copy } from "react-feather";
import CustomButton from "../CustomButton/CustomButton";
import {
  Autocomplete,
  TextField,
  CircularProgress,
  Tooltip,
  IconButton,
} from "@mui/material";
import { Grid } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { companyServices } from "../../services/company.services";
import AlertDialogSlide from "../AlertDialogSlide/AlertDialogSlide";
import { agreementServices } from "../../services/agreement.services";
import { takeoffServices } from "../../services/takeoff.services";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";
import ContextMenu from "../ContextMenu/ContextMenu";
import { encryptObject } from "../../helpers/link-variables";
import CustomModal from "../CustomModal/CustomModal";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Example from "./example";
import Settings from "@mui/icons-material/Settings";
import { accountServices } from "../../services/account.services";
import { CustomDatePickerComponent } from "../CustomDatePickerComponent/CustomDatePickerComponent";

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;

  const createSortHandler = (property) => {
    onRequestSort(property);
  };

  return (
    <TableHead style={{ backgroundColor: "#0077c6" }}>
      <TableRow>
        <TableCell
          padding="checkbox"
          sx={{ backgroundColor: "#0077c6" }}
        ></TableCell>
        {headCells.map((headCell) =>
          headCell.visibility ? (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{ color: "white", backgroundColor: "#0077c6" }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={() => createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ) : null
        )}
      </TableRow>
    </TableHead>
  );
}

export default function TakeoffTable({ tableID = 2 }) {
  const location = useLocation();
  const rolePermission = useSelector((state) =>
    state.user.user ? state.user.user.user.role.takeoff : ""
  );

  const orderPermission = useSelector((state) =>
    state.user.user ? state.user.user.user.role.orders : ""
  );

  const history = useHistory();

  //redirection
  if (rolePermission === "N") {
    history.push("/Dashboard");
  }

  const [newOrderLoading, setNewOrderLoading] = React.useState(false);

  //for sorting
  const [orderColumn, setOrderColumn] = React.useState("");
  const [orderDirection, setOrderDirection] = React.useState("");

  //holds rows selected
  const [selected, setSelected] = React.useState([]);

  const [headCells, setHeadCells] = React.useState([]);

  const [headCellsEdit, setHeadCellsEdit] = React.useState([]);

  //holds page we are in in table
  const [page, setPage] = React.useState(0);

  //holds rows per page in the table
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  //holds rows data to be displayed
  const [rows, setRows] = React.useState([]);

  const [dateError, setDateError] = React.useState("");

  //displays filters
  const [applyFilters, setApplyFilters] = React.useState(false);

  //holds error if any while fetching data from the database
  const [error, setError] = React.useState("");

  //holds the number of selected rows
  var numSelected = selected.length;

  //holds the total number of records that match the filters in the database
  const [numberRecords, setNumberRecords] = React.useState(0);

  //used to refetch the data from the database
  const [resubmit, setResubmit] = React.useState(false);

  /**For open sackbar after save of table columns sorting */
  const [openSnackbar2, setOpenSnackbar2] = React.useState(false);

  //used to make page load while waiting for data to be fetched from databse
  const [pageLoad, setPageLoad] = React.useState(false);

  //used to hold errors if any during the delete process
  const [deleteError, setDeleteError] = React.useState("");

  //for snackbar display
  const [success, setSuccess] = React.useState(
    location.state
      ? location.state.success
        ? location.state.success
        : false
      : false
  );

  //for filters
  const [takeoffName, setTakeoffName] = React.useState("");
  const [agreementName, setAgreementName] = React.useState([]);
  const [builderName, setBuilderName] = React.useState([]);
  const [agreementIDs, setAgreementIDs] = React.useState([]);
  const [builderIDs, setBuilderIDs] = React.useState([]);
  const [installerName, setInstallerName] = React.useState([]);
  const [installerID, setInstallerID] = React.useState([]);
  const [community, setCommunity] = React.useState("");
  const [planNumber, setPlanNumber] = React.useState("");
  const [planName, setPlanName] = React.useState("");
  const [dateCreated, setDateCreated] = React.useState(null);
  const [dateModified, setDateModified] = React.useState(null);
  const [dateCreatedTo, setDateCreatedTo] = React.useState(null);
  const [dateModifiedTo, setDateModifiedTo] = React.useState(null);

  /**to open date picker in filter when user clicks on them */
  const [openCreatedFrom, setOpenCreatedFrom] = React.useState(false);
  const [openCreatedTo, setOpenCreatedTo] = React.useState(false);
  const [openModifiedFrom, setOpenModifiedFrom] = React.useState(false);
  const [openModifiedTo, setOpenModifiedTo] = React.useState(false);

  const createdFromRef = React.useRef(null);
  const createdToRef = React.useRef(null);
  const modifiedFromRef = React.useRef(null);
  const modifiedToRef = React.useRef(null);

  /**To open change columns */
  const [changeColumns, setChangeColumns] = React.useState(false);

  /**For context menu */
  const [contextMenu, setContextMenu] = React.useState(null);

  /**For loading of save and cancel in edit table columns */
  const [editColumnsLoading, setEditColumnsLoading] = React.useState(false);

  /**Holds table row that has been right clicked */
  const [contextMenuData, setContextMenuData] = React.useState(null);

  //used to display alert dialog before delete
  const [deleteRecord, setDeleteRecord] = React.useState(false);

  //used to make delete button loading while delete process is still not finished
  const [loading, setLoading] = React.useState(false);

  //getting data from redux
  const companyType = useSelector((state) =>
    state.user.user ? state.user.user.user.company.type : ""
  );

  const companyID = useSelector((state) =>
    state.user.user ? state.user.user.user.company.companyID : ""
  );
  const userID = useSelector((state) =>
    state.user.user ? state.user.user.user.userID : ""
  );

  const handleDateSubmit = async (date) => {
    const regex = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/g;

    if (!regex.test(date)) {
      return "Invalid Date Format";
    }

    const searchDate = moment(date).isValid()
      ? moment(date).format("MM/DD/YYYY")
      : "Invalid Date Format";

    return searchDate;
  };

  /*Convert UTC to local time*/
  const convertUTC = (utcDate) => {
    var date = new Date(utcDate + "Z");
    var originDate = new Date("January 1, 2000 00:00:00");
    if (date < originDate) {
      return "";
    } else {
      const d = date.toString();
      if (d === "Invalid Date") {
        return "";
      }
      const m = date.toLocaleString("default", { month: "long" });
      return m + " " + d.substring(8, 10) + ", " + d.substring(11, 15);
    }
  };

  const checkDateError = async () => {
    /**Function to check if there is an error in the date format */
    var handleDateErrorDateCreated = "";
    var handleDateErrorDateModified = "";
    var handleDateErrorDateCreatedTo = "";
    var handleDateErrorDateModifiedTo = "";

    if (dateCreated !== null)
      handleDateErrorDateCreated = await handleDateSubmit(dateCreated);
    if (dateModified !== null)
      handleDateErrorDateModified = await handleDateSubmit(dateModified);
    if (dateCreatedTo !== null)
      handleDateErrorDateCreatedTo = await handleDateSubmit(dateCreatedTo);
    if (dateModifiedTo !== null)
      handleDateErrorDateModifiedTo = await handleDateSubmit(dateModifiedTo);

    if (
      handleDateErrorDateCreated === "Invalid Date Format" ||
      handleDateErrorDateModified === "Invalid Date Format" ||
      handleDateErrorDateCreatedTo === "Invalid Date Format" ||
      handleDateErrorDateModifiedTo === "Invalid Date Format"
    ) {
      if (handleDateErrorDateCreated === "Invalid Date Format")
        setDateError(
          "Wrong date format for the field Created Date From. Please make sure you are entering the date as MM/DD/YYYY."
        );

      if (handleDateErrorDateModified === "Invalid Date Format")
        setDateError(
          "Wrong date format for the field Modified Date From. Please make sure you are entering the date as MM/DD/YYYY."
        );

      if (handleDateErrorDateCreatedTo === "Invalid Date Format")
        setDateError(
          "Wrong date format for the field Created Date To. Please make sure you are entering the date as MM/DD/YYYY."
        );

      if (handleDateErrorDateModifiedTo === "Invalid Date Format")
        setDateError(
          "Wrong date format for the field Modified Date To. Please make sure you are entering the date as MM/DD/YYYY."
        );

      return true;
    } else {
      return false;
    }
  };

  const getTableColumns = async () => {
    await accountServices
      .getTableColumnsBasedOnUserPreferences({
        userId: userID,
        tableId: tableID,
      })
      .then((response) => {
        const copy1 = response.data.columns.map((item) => ({ ...item }));
        const copy2 = response.data.columns.map((item) => ({ ...item }));
        setHeadCells(copy1);
        setHeadCellsEdit(copy2);

        if (orderColumn === "" && orderDirection === "") {
          setOrderColumn(response.data.sortingColumn);
          setOrderDirection(response.data.sortingDirection);

          fetchDataFromDatabase(
            response.data.sortingColumn,
            response.data.sortingDirection
          );
        } else {
          fetchDataFromDatabase();
        }
      })
      .catch((error) => {
        setError(error.response.data.error.error);
      });
  };

  const updateUserTablePreferences = async () => {
    setEditColumnsLoading(true);
    await accountServices
      .updateUserTablePreferences({
        userId: userID,
        tableId: tableID,
        columns: headCellsEdit,
        SortColumn: orderColumn,
        SortDirection: orderDirection,
      })
      .then((resonse) => {
        setResubmit(!resubmit);
        setOpenSnackbar2(true);
        setEditColumnsLoading(false);
        setChangeColumns(false);
      })
      .catch((error) => {
        setError(error.response.data.error.error);
        setEditColumnsLoading(false);
      });
  };

  const getFilterData = async () => {
    var dateErr = await checkDateError();

    if (dateErr) {
      setPageLoad(false);
    } else {
      //function to fetch filter data
      agreementServices
        .getAgreements({
          Keys: { Page: page + 1, PageSize: rowsPerPage, KeyWord: "" },
          Filters: {
            Name: "",
            Builders: [],
            Installers: [],
            RealEstateTypes: [],
            DateCreated:
              dateCreated === "" || dateCreated === null
                ? null
                : new Date(dateCreated).toISOString().split("T")[0],
            DateModified:
              dateModified === "" || dateModified === null
                ? null
                : new Date(dateModified).toISOString().split("T")[0],
          },
          isLevven: true,
          CompanyID: companyID,
        })
        .then((response) => {
          setAgreementName(response.data.agreements);
        })
        .catch((error) => {
          setError(error.response.data.error.error);
        });
    }
    if (companyType === "L") {
      var data = { CompanyType: "B", IsLevven: true, CompanyID: companyID };
      companyServices
        .GetChildCompaniesForTakeoffByType(data)
        .then((response) => {
          setBuilderName(response.data);
        })
        .catch((error) => {
          setError(error.response.data.error.error);
        });

      var data2 = { CompanyType: "I", IsLevven: true, CompanyID: companyID };
      companyServices
        .GetChildCompaniesForTakeoffByType(data2)
        .then((response) => {
          setInstallerName(response.data);
        })
        .catch((error) => {
          setError(error.response.data.error.error);
        });
    } else {
      if (companyType === "B") {
        var data3 = { CompanyType: "I", IsLevven: false, CompanyID: companyID };
        companyServices
          .GetChildCompaniesForTakeoffByType(data3)
          .then((response) => {
            setInstallerName(response.data);
          })
          .catch((error) => {
            setError(error.response.data.error.error);
          });
      } else if (companyType === "I") {
        var data4 = { CompanyType: "B", IsLevven: false, CompanyID: companyID };
        companyServices
          .GetChildCompaniesForTakeoffByType(data4)
          .then((response) => {
            setBuilderName(response.data);
          })
          .catch((error) => {
            setError(error.response.data.error.error);
          });
      }
    }
  };

  const fetchDataFromDatabase = async (column = "", direction = "") => {
    var dateErr = await checkDateError();

    if (dateErr) {
      setPageLoad(false);
    } else {
      setRows([]);
      setSelected([]);

      //function to fetch data from the database according to filters
      var array = [];

      agreementIDs.forEach((agreement) => {
        array.push(agreement.agreementID);
      });

      var array2 = [];

      builderIDs.forEach((builder) => {
        array2.push(builder.companyID);
      });

      var array3 = [];

      installerID.forEach((agreement) => {
        array3.push(agreement.companyID);
      });

      const datas = {
        Keys: { Page: page + 1, PageSize: rowsPerPage },
        Filters: {
          TakeoffName: takeoffName,
          Agreements: array,
          Builders: array2,
          Installers: array3,
          Community: community,
          PlanNumber: planNumber,
          PlanName: planName,
          DateCreated:
            dateCreated === "" || dateCreated === null
              ? null
              : moment(new Date(dateCreated)).format("MM/DD/YYYY"),
          DateModified:
            dateModified === "" || dateModified === null
              ? null
              : moment(new Date(dateModified)).format("MM/DD/YYYY"),
          DateCreatedTo:
            dateCreatedTo === "" || dateCreatedTo === null
              ? null
              : moment(new Date(dateCreatedTo)).format("MM/DD/YYYY"),
          DateModifiedTo:
            dateModifiedTo === "" || dateModifiedTo === null
              ? null
              : moment(new Date(dateModifiedTo)).format("MM/DD/YYYY"),
        },
        IsLevven: companyType === "L" ? true : false,
        CompanyID: companyID,
        orderDirection: direction === "" ? orderDirection : direction,
        orderColumn: column === "" ? orderColumn : column,
      };

      takeoffServices
        .getTakeoffList(datas)
        .then((response) => {
          setPageLoad(false);
          setNumberRecords(response.data.totalNumberOfRecords);

          setRows(response.data.takeoffList);
        })
        .catch((error) => {
          setError(error.response.data.error.error);
          setPageLoad(false);
        });
    }
  };

  useEffect(() => {
    //we get the data for the rows as well as the filters

    window.scrollTo(0, 0);
    setPageLoad(true);
    setError("");
    setHeadCells([]);
    setHeadCellsEdit([]);
    setRows([]);
    getTableColumns();
    getFilterData();
  }, [page, rowsPerPage, resubmit]);

  const deleteTakeoffs = () => {
    //function to delete Takeoff Values selected
    setLoading(true);
    setDeleteRecord(false);
    takeoffServices
      .deleteTakeoff({ TakeoffList: selected })
      .then(() => {
        setLoading(false);
        setSuccess(true);
        setSelected([]);
        setResubmit(!resubmit);
      })
      .catch((error) => {
        setLoading(false);
        setSelected([]);
        setDeleteError(error.response.data.error.error);
        setResubmit(!resubmit);
      });
  };

  const handleRequestSort = (property) => {
    //for sorting
    const isAsc = orderColumn === property && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderColumn(property);
    setResubmit(!resubmit);
  };

  //Function to select row on click of anywhere on the row
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    //function to change page in table
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    //function to change rows per page in table
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const submitForm = (event) => {
    //function to submit filter data
    event.preventDefault();
    setPage(0);
    setResubmit(!resubmit);
  };

  const viewData = (id, name, type, agreementID) => {
    //function ot go to takeoff tabs to view takeoff info
    history.push({
      pathname: "/takeoff-information",
      state: {
        id: id,
        name: name,
        doesTakeoff:
          type === companyType ? true : companyType === "L" ? true : false,
        agreementID: agreementID,
      },
    });
  };

  //to know if a row is selected or not
  const isSelected = (id) => selected.indexOf(id) !== -1;

  const clearFilters = () => {
    //function to clear filter data selected
    setTakeoffName("");
    setAgreementIDs([]);
    setBuilderIDs([]);
    setInstallerID([]);
    setPlanNumber("");
    setPlanName("");
    setCommunity("");
    setDateCreatedTo(null);
    setDateModifiedTo(null);
    setDateCreated(null);
    setDateModified(null);
    setResubmit(!resubmit);
  };

  const goToOrder = async () => {
    /**Function to go to Order */
    setNewOrderLoading(true);

    if (selected.length === 1) {
      await takeoffServices
        .getTakeoffByID(selected[0])
        .then((response) => {
          setNewOrderLoading(false);
          history.push({
            pathname: "/create-order",
            state: {
              takeoff: response.data,
              fromTakeoff: true,
            },
          });
        })
        .catch((error) => {
          setError(error.response.data.error.error);
          setNewOrderLoading(false);
        });
    } else {
      history.push({
        pathname: "/create-order",
      });
    }
  };

  /**Function to open context menu */
  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  /**Function to close context menu */
  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const openInNewTab = async () => {
    const objectToBeSent = {
      id: contextMenuData.takeoffID,
      name: contextMenuData.name,
      doesTakeoff:
        contextMenuData.agreement.takeoff === companyType
          ? true
          : companyType === "L"
          ? true
          : false,
      agreementID: contextMenuData.agreementID,
    };

    const encryptedDate = await encryptObject(objectToBeSent);

    window.open(
      "/takeoff-information?otbd=" + encodeURIComponent(encryptedDate),
      "_blank"
    );

    setContextMenu(null);
  };

  return (
    <>
      <SnackBar
        open={success}
        message="TakeOff(s) Deleted Successfully"
        severity="success"
        handleClose={() => setSuccess(false)}
      />
      {deleteRecord ? (
        <AlertDialogSlide
          title="Takeoff Deletion"
          textContent={"Are you sure you want to delete these records?"}
          save={deleteTakeoffs}
          saveText="Yes"
          deleteDialog={true}
          unsave={() => setDeleteRecord(false)}
          unsaveText="No"
        />
      ) : (
        ""
      )}
      <SnackBar
        open={openSnackbar2}
        message="List Columns Altered Successfully"
        severity="success"
        handleClose={() => setOpenSnackbar2(false)}
      />
      {dateError ? (
        <AlertSnackBar
          message={dateError}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setDateError("");
          }}
          open={dateError !== ""}
        />
      ) : (
        ""
      )}

      {deleteError ? (
        <AlertSnackBar
          message={deleteError}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setDeleteError("");
          }}
          open={deleteError !== ""}
        />
      ) : null}
      {error ? (
        <AlertSnackBar
          message={error}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setError("");
          }}
          open={error !== ""}
        />
      ) : null}
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <Toolbar
            sx={{
              overflow: "auto",
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              ...(numSelected > 0 && {
                bgcolor: "#d4e8f6",
              }),
            }}
          >
            {numSelected > 0 ? (
              <Typography
                sx={{ flex: "1 1 100%" }}
                color="#303030"
                variant="h6"
                component="div"
              >
                {numSelected} row(s) selected
              </Typography>
            ) : (
              <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Takeoffs
              </Typography>
            )}

            {numSelected > 0 ? (
              <>
                <CustomButton
                  startIcon={<Filter />}
                  size="medium"
                  tableButton={true}
                  toggle={() => setApplyFilters(!applyFilters)}
                >
                  {applyFilters ? "Hide Filters" : "Show Filters"}
                </CustomButton>
                {orderPermission === "M" ? (
                  <CustomButton
                    startIcon={<Plus />}
                    size="medium"
                    tableButton={true}
                    loading={newOrderLoading}
                    toggle={() => goToOrder()}
                  >
                    New Order
                  </CustomButton>
                ) : null}

                {rolePermission === "M" ? (
                  <>
                    {numSelected === 1 ? (
                      <CustomButton
                        startIcon={<Copy />}
                        size="medium"
                        tableButton={true}
                        toggle={() =>
                          history.push({
                            pathname: "/create-takeoff",
                            state: {
                              duplicate: true,
                              takeoffID: selected[0],
                            },
                          })
                        }
                      >
                        Duplicate
                      </CustomButton>
                    ) : null}

                    <CustomButton
                      startIcon={<Trash2 />}
                      size="medium"
                      tableButton={true}
                      loading={loading}
                      toggle={() => setDeleteRecord(true)}
                    >
                      Delete
                    </CustomButton>
                    <Tooltip title="Customize View">
                      <IconButton onClick={() => setChangeColumns(true)}>
                        <Settings fontSize="medium" />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                <CustomButton
                  startIcon={<Filter />}
                  size="medium"
                  tableButton={true}
                  toggle={() => setApplyFilters(!applyFilters)}
                >
                  {applyFilters ? "Hide Filters" : "Show Filters"}
                </CustomButton>
                {orderPermission === "M" ? (
                  <CustomButton
                    startIcon={<Plus />}
                    size="medium"
                    tableButton={true}
                    loading={newOrderLoading}
                    toggle={() => goToOrder()}
                  >
                    New Order
                  </CustomButton>
                ) : null}
                {rolePermission === "M" ? (
                  <>
                    <CustomButton
                      startIcon={<Plus />}
                      size="medium"
                      tableButton={true}
                      toggle={() => history.push("/create-takeoff")}
                    >
                      New Takeoff
                    </CustomButton>
                  </>
                ) : (
                  ""
                )}
                <Tooltip title="Customize View">
                  <IconButton onClick={() => setChangeColumns(true)}>
                    <Settings fontSize="medium" />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Toolbar>

          {/*Filter*/}
          {applyFilters ? (
            <div style={{ backgroundColor: "#EFEFF0", paddingTop: "10px" }}>
              <Typography
                variant="h6"
                sx={{ fontWeight: 700, marginLeft: "10px" }}
              >
                Filter Options
              </Typography>
              <br />
              <form onSubmit={submitForm}>
                <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                  <Grid container spacing={3}>
                    {companyType === "L" || companyType === "I" ? (
                      <Grid item xs={9} md={6} lg={2}>
                        <Autocomplete
                          multiple
                          id="size-small-outlined-multi"
                          size="small"
                          options={builderName}
                          fullWidth
                          getOptionLabel={(option) => option.name}
                          onChange={(event, value) => {
                            setBuilderIDs(value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Builder Name"
                              inputProps={{
                                ...params.inputProps,
                              }}
                              autoComplete="off"
                            />
                          )}
                        />
                      </Grid>
                    ) : null}

                    <Grid item xs={9} md={6} lg={2}>
                      <TextField
                        value={community}
                        onChange={(e) => setCommunity(e.target.value)}
                        size="small"
                        label="Community"
                        fullWidth
                        inputProps={{
                          maxLength: 100,
                        }}
                        autoComplete="off"
                      />
                    </Grid>
                    <Grid item xs={9} md={6} lg={2}>
                      <TextField
                        value={planName}
                        onChange={(e) => setPlanName(e.target.value)}
                        size="small"
                        label="Model/Plan Name"
                        fullWidth
                        inputProps={{
                          maxLength: 100,
                        }}
                        autoComplete="off"
                      />
                    </Grid>
                    <Grid item xs={9} md={6} lg={2}>
                      <TextField
                        value={planNumber}
                        onChange={(e) => setPlanNumber(e.target.value)}
                        size="small"
                        label="Plan Number"
                        fullWidth
                        inputProps={{
                          maxLength: 100,
                        }}
                        autoComplete="off"
                      />
                    </Grid>

                    {companyType === "L" || companyType === "B" ? (
                      <Grid item xs={9} md={6} lg={2}>
                        <Autocomplete
                          multiple
                          id="size-small-outlined-multi"
                          size="small"
                          options={installerName}
                          fullWidth
                          getOptionLabel={(option) => option.name}
                          onChange={(event, value) => {
                            setInstallerID(value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Installer Name"
                              inputProps={{
                                ...params.inputProps,
                              }}
                              autoComplete="off"
                            />
                          )}
                        />
                      </Grid>
                    ) : null}
                    <Grid item xs={9} md={6} lg={2}>
                      <Autocomplete
                        multiple
                        id="size-small-outlined-multi"
                        size="small"
                        options={agreementName}
                        fullWidth
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => {
                          setAgreementIDs(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Agreement Name"
                            inputProps={{
                              ...params.inputProps,
                            }}
                            autoComplete="off"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={9} md={6} lg={2}>
                      <CustomDatePickerComponent
                        label="Created Date From"
                        value={dateCreated}
                        setValue={setDateCreated}
                      />
                    </Grid>

                    <Grid item xs={9} md={6} lg={2}>
                      <CustomDatePickerComponent
                        label="Created Date To"
                        value={dateCreatedTo}
                        setValue={setDateCreatedTo}
                      />
                    </Grid>
                    <Grid item xs={9} md={6} lg={2}>
                      <CustomDatePickerComponent
                        label="Modified Date From"
                        value={dateModified}
                        setValue={setDateModified}
                      />
                    </Grid>
                    <Grid item xs={9} md={6} lg={2}>
                      <CustomDatePickerComponent
                        label="Modified Date To"
                        value={dateModifiedTo}
                        setValue={setDateModifiedTo}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div
                  style={{
                    backgroundColor: "#EFEFF0",
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <CustomButton
                    clearButton={true}
                    size="medium"
                    type={"submit"}
                  >
                    Apply Filters
                  </CustomButton>
                  <CustomButton
                    filterButton={true}
                    size="medium"
                    toggle={clearFilters}
                  >
                    Clear Filters
                  </CustomButton>
                </div>
              </form>
            </div>
          ) : (
            ""
          )}

          <TableContainer
            className={
              applyFilters ? "table-container-filters" : "table-container"
            }
          >
            <Table
              stickyHeader
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
            >
              <EnhancedTableHead
                order={orderDirection}
                orderBy={orderColumn}
                onRequestSort={handleRequestSort}
                headCells={headCells}
              />
              <TableBody
                onContextMenu={
                  pageLoad === false && numberRecords > 0
                    ? handleContextMenu
                    : () => {
                        return null;
                      }
                }
              >
                {pageLoad === true ? (
                  <TableRow>
                    <TableCell colSpan="10" style={{ textAlign: "center" }}>
                      <CircularProgress sx={{ color: "#0077c6" }} />
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}
                {numberRecords === 0 && pageLoad === false ? (
                  <TableRow>
                    <TableCell
                      colSpan="10"
                      style={{ color: "grey", textAlign: "center" }}
                    >
                      It seems there are no Takeoffs to display.{" "}
                      {rolePermission === "M" ? (
                        <Typography
                          variant="p"
                          sx={{
                            textDecoration: "underline",
                            color: "#0077c6",
                            cursor: "pointer",
                          }}
                          onClick={() => history.push("/create-takeoff")}
                        >
                          Would you like to create one?
                        </Typography>
                      ) : null}
                    </TableCell>
                  </TableRow>
                ) : (
                  rows.map((row, index) => {
                    const isItemSelected = isSelected(row.takeoffID);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        sx={{ cursor: "pointer" }}
                        tabIndex={-1}
                        key={row.takeoffID}
                      >
                        <TableCell padding="checkbox">
                          {rolePermission === "M" &&
                          (companyType === row.agreement.takeoff ||
                            companyType === "L") ? (
                            <Checkbox
                              onClick={(event) =>
                                handleClick(event, row.takeoffID)
                              }
                              checkedIcon={
                                <CheckBoxIcon sx={{ color: "#badaf1" }} />
                              }
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </TableCell>
                        {headCells.map((headCell) => {
                          if (headCell.visibility)
                            return (
                              <TableCell
                                onContextMenu={() => {
                                  setContextMenuData(row);
                                }}
                                onClick={() =>
                                  viewData(
                                    row.takeoffID,
                                    row.name,
                                    row.agreement.takeoff,
                                    row.agreementID
                                  )
                                }
                              >
                                {headCell.columnID === 1
                                  ? row.agreement.builder.name
                                  : headCell.columnID === 2
                                  ? row.community === "NA" ||
                                    row.community === null
                                    ? ""
                                    : row.community
                                  : headCell.columnID === 3
                                  ? row.planName
                                  : headCell.columnID === 4
                                  ? row.planNum
                                  : headCell.columnID === 5
                                  ? row.agreement.installer
                                    ? row.agreement.installer.name
                                    : ""
                                  : headCell.columnID === 6
                                  ? row.agreement.name
                                  : headCell.columnID === 7
                                  ? convertUTC(row.creationDate)
                                  : headCell.columnID === 9
                                  ? row.name
                                  : row.modificationDate
                                  ? convertUTC(row.modificationDate)
                                  : ""}
                              </TableCell>
                            );
                          else return null;
                        })}

                        {/* <TableCell
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                          onClick={() =>
                            viewData(
                              row.takeoffID,
                              row.name,
                              row.agreement.takeoff,
                              row.agreementID
                            )
                          }
                        >
                          {row.agreement.builder.name}
                        </TableCell>
                        <TableCell
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                          onClick={() =>
                            viewData(
                              row.takeoffID,
                              row.name,
                              row.agreement.takeoff,
                              row.agreementID
                            )
                          }
                        >
                          {row.community === "NA" ? "" : row.community}
                        </TableCell>
                        <TableCell
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                          onClick={() =>
                            viewData(
                              row.takeoffID,
                              row.name,
                              row.agreement.takeoff,
                              row.agreementID
                            )
                          }
                        >
                          {row.planName}
                        </TableCell>
                        <TableCell
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                          onClick={() =>
                            viewData(
                              row.takeoffID,
                              row.name,
                              row.agreement.takeoff,
                              row.agreementID
                            )
                          }
                        >
                          {row.planNum}
                        </TableCell>

                        <TableCell
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                          onClick={() =>
                            viewData(
                              row.takeoffID,
                              row.name,
                              row.agreement.takeoff,
                              row.agreementID
                            )
                          }
                        >
                          {row.agreement.installer
                            ? row.agreement.installer.name
                            : ""}
                        </TableCell>
                        <TableCell
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                          onClick={() =>
                            viewData(
                              row.takeoffID,
                              row.name,
                              row.agreement.takeoff,
                              row.agreementID
                            )
                          }
                        >
                          {row.agreement.name}
                        </TableCell>
                        <TableCell
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                          onClick={() =>
                            viewData(
                              row.takeoffID,
                              row.name,
                              row.agreement.takeoff,
                              row.agreementID
                            )
                          }
                        >
                          {convertUTC(row.creationDate)}
                        </TableCell>
                        <TableCell
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                          onClick={() =>
                            viewData(
                              row.takeoffID,
                              row.name,
                              row.agreement.takeoff,
                              row.agreementID
                            )
                          }
                        >
                          {row.modificationDate
                            ? convertUTC(row.modificationDate)
                            : ""}
                        </TableCell> */}
                      </TableRow>
                    );
                  })
                )}
                <>
                  <ContextMenu
                    contextMenu={contextMenu}
                    handleClose={handleCloseContextMenu}
                    openInNewTab={openInNewTab}
                  />
                </>
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[25, 50, 200]}
            component="div"
            count={numberRecords}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      {changeColumns ? (
        <>
          <CustomModal openModal={changeColumns} width="70%">
            <Grid
              container
              // spacing={3}

              style={{
                overflow: "auto",
                maxHeight: "50vh",
                padding: "20px",
                justifyContent: "center",
                // textAlign: "justify",
                // textJustify: "inter - word",
              }}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="h6" component="span" align="left">
                  Customize Takeoff View
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div
                  style={{
                    width: "100%",
                    backgroundColor: "#0077c6",
                    padding: "16px 0",
                  }}
                >
                  <Grid container>
                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      xl={4}
                      style={{
                        margin: "auto",
                      }}
                    >
                      <Typography
                        component="span"
                        variant="body2"
                        color="white"
                        style={{ fontWeight: "500" }}
                      >
                        Name
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      md={2}
                      lg={2}
                      xl={2}
                      style={{
                        margin: "auto",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        component="span"
                        variant="body2"
                        align="left"
                        color="white"
                        style={{ fontWeight: "500" }}
                      >
                        Show/Hide
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      md={2}
                      lg={2}
                      xl={2}
                      style={{
                        margin: "auto",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        component="span"
                        variant="body2"
                        color="white"
                        style={{ fontWeight: "500" }}
                      >
                        Sort
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      lg={3}
                      xl={3}
                      style={{
                        margin: "auto",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        component="span"
                        variant="body2"
                        color="white"
                        style={{ fontWeight: "500" }}
                      >
                        Sort Direction
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
              style={{
                overflow: "auto",
                maxHeight: "50vh",
                padding: "0px 0px 20px 20px",
                justifyContent: "center",
                // textAlign: "justify",
                // textJustify: "inter - word",
              }}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <DndProvider backend={HTML5Backend}>
                  <Example
                    steps={headCellsEdit}
                    setSteps={setHeadCellsEdit}
                    sorting={orderColumn}
                    setSorting={setOrderColumn}
                    direction={orderDirection}
                    setDirection={setOrderDirection}
                  />
                </DndProvider>
              </Grid>
            </Grid>
            <Grid container sx={{ paddingTop: "25px" }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    marginTop: "30px",
                  }}
                >
                  <CustomButton
                    loading={editColumnsLoading}
                    toggle={() => {
                      var tmp = headCells.map((item) => ({ ...item }));
                      setHeadCellsEdit(tmp);
                      setChangeColumns(false);
                    }}
                  >
                    Cancel
                  </CustomButton>
                  <div style={{ width: "10px" }} />
                  <CustomButton
                    loading={editColumnsLoading}
                    toggle={() => {
                      updateUserTablePreferences();
                    }}
                  >
                    Save
                  </CustomButton>
                </div>
              </Grid>
            </Grid>
          </CustomModal>
        </>
      ) : (
        ""
      )}
    </>
  );
}
