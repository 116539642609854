import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import CustomButton from "../CustomButton/CustomButton";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  CircularProgress,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  IconButton,
  Tooltip,
  Modal,
} from "@mui/material";
import Underline from "../Underline/Underline";
import { agreementServices } from "../../services/agreement.services";
import { companyServices } from "../../services/company.services";
import { accountServices } from "../../services/account.services";
import { takeoffServices } from "../../services/takeoff.services";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { orderServices } from "../../services/orders.service";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";
import {
  AlertTriangle,
  BookOpen,
  Compass,
  Edit,
  Info,
  Phone,
  Plus,
  PlusCircle,
  Pocket,
  Save,
  Send,
  Trash2,
  X,
} from "react-feather";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { systemDataServices } from "../../services/systemData.services";
import { productServices } from "../../services/product.service";
import { shippingaddressServices } from "../../services/shippingaddress.services";
import { countryServices } from "../../services/country.services";
import CustomModal from "../CustomModal/CustomModal";
import ShippingAddressForm from "../ShippingAddressForm/ShippingAddressForm";
import useUnsavedChangesWarning from "../../hooks/useUnsavedChangesWarning";
import AlertDialogSlide from "../AlertDialogSlide/AlertDialogSlide";
import OtherOrdersTable from "../OtherOrdersTable.jsx/OtherOrdersTable";
import { melissaServices } from "../../services/melissa.services";
import { getMelissaLog } from "../../helpers/melissa-api-logs";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { useLogs } from "../../hooks/useLogs";
import { CustomDatePickerComponent } from "../CustomDatePickerComponent/CustomDatePickerComponent";
import SnackBar from "../SnackBar/SnackBar";
import InfoSnackbar from "../InfoSnackbar/InfoSnackbar";

const useStyles = makeStyles((theme) => ({
  divStyle5: {
    width: "10px",
    height: "",
    "@media(max-width: 1000px)": {
      width: "",
      height: "10px",
    },
  },
  buttonStyle5: {
    display: "flex",
    textAlign: "right",
    flexDirection: "row",
    justifyContent: "right",
    overflow: "auto",

    "@media(max-width: 1000px)": {
      display: "block",
    },
  },
}));

const OrderForm = forwardRef(
  (
    {
      adminManage = false,
      create = false,
      receivedOrderID = 0,
      openDrawerMenu,
      setOpenSnackBar,
      windowWidth,
      setFormChanged,
      setOrderStatusInTabs,
      orderStatusInTabs,
      orderFulfillmentThreshold,
      getError,
      setOpenLevvenContactsModal,
      getOrderModificationLevvenContactText,
      setDeleteRecord,
      setTabButtonLoading,
      setCanSubmitAlert,
      setCanSubmitModal,
      setJobNumInTabs,
      setOrderInTabs,
      minRequestedDeliveryDate = null,
      setRequestedDateInTabs,
      setDateAlertDialogOpen,
      setPurchaseOrderNumberInTabs,
      submitOrder,
      finalizeOrder,
      submitOrderPermission,
      rolePermission,
      reopenLoading,
      setReopenDialog,
      submitLoading,
      isRMA = false,
      highlightPO,
      setHighlightPO,
      highlightDate,
      setHighlightDate,
      refetchOrderData,
      setCompanyTypeForWarningCheck,
      setAddressVerificationLevel,
      addressVerificationLevel,
      orderType,
      setOrderType,
    },
    ref
  ) => {
    const classes = useStyles();
    /*Order Object*/
    const [order, setOrder] = useState();

    /*for shipping address*/

    /*Selectors*/
    const [orderTypes, setOrderTypes] = useState([
      "Project Pack for Home",
      "Bulk Inventory",
    ]);
    const [shippingMethods, setShippingMethods] = useState([
      "Prepaid and charge",
      "Prepaid",
      "Local pickup",
    ]);
    const [purchasers, setPurchasers] = useState([]);
    const [takeoffs, setTakeoffs] = useState([]);
    const [installerContacts, setInstallerContacts] = useState([]);
    const [switchColors, setSwitchColors] = useState([]);
    const [unshippedOrders, setUnshippedOrders] = useState([]);
    const [shippingAddresses, setShippingAddresses] = useState([]);
    const [billingCompanies, setBillingCompanies] = useState([]);
    const [companiesUsers, setCompaniesUsers] = useState([]);
    const [countries, setCountries] = useState([]);
    const [stateProvinces, setStateProvinces] = useState([]);

    /*Company*/
    const [companies, setCompanies] = useState([]);
    const [company, setCompany] = useState("");
    const [companyError, setCompanyError] = useState(false);

    /*Order Status*/
    const [orderStatus, setOrderStatus] = useState("");

    //holds selected rows in unshipped orders table
    const [selected, setSelected] = useState([]);

    /*Purchasing Email*/
    const [purchaserEmail, setPurchaserEmail] = useState();
    const [purchaserEmailError, setPurchaserEmailError] = useState(false);

    //to call the save after all new values have been set
    const [melissaSave, setMelissaSave] = useState(false);

    /*Order Type*/
    const [orderTypeError, setOrderTypeError] = useState(false);

    /*Takeoff*/
    const [takeoff, setTakeoff] = useState();
    const [takeoffError, setTakeoffError] = useState(false);

    //hook to call log function
    const [
      logLoading,
      logError,
      handleLogCloseError,
      logUnsaveLoading,
      addLogAndLogDetail,
    ] = useLogs();

    /**Holds address to log */
    const [AddressToLog, setAddressToLog] = useState("");

    /*Agreement*/
    const [agreement, setAgreement] = useState();

    /*InstallerContact*/
    const [installerContact, setInstallerContact] = useState();

    /*Special Negotiated Price*/
    const [specialNegotiated, setSpecialNegotiated] = useState(false);

    /*Purchase Order Number*/
    const [purchaseOrderNb, setPurchaseOrderNb] = useState("");
    const [purchaseOrderNbError, setPurchaseOrderNbError] = useState(false);

    /*value for max value of all orders possible*/
    const [maxOrderTotal, setMaxOrderTotal] = useState(0);

    //for total price of orders
    const [orderTotal, setOrderTotal] = useState(0);

    /*Requested Delivery Date*/
    const [requestedDate, setRequestedDate] = useState(null);
    const [requestedDateError, setRequestedDateError] = useState("");

    /*Requested Delivery Date*/
    const [forModelHome, setForModelHome] = useState(false);

    /*Override Default Switch Color?*/
    const [overDefSwitchColor, setOverDefSwitchColor] = useState();
    const [defSwitch, setDefSwitch] = useState();

    /*Ship to Address*/
    const [shipToAddress, setShipToAddress] = useState(null);
    const [shipToAddressError, setShipToAddressError] = useState(false);

    const [addressError, setAddressError] = useState(false);

    /*State to save the ID of last added shipping address*/
    const [toUpdateShipAddID, setToUpdateShipAddID] = useState(0);

    // Billing Address
    const [billingAddress, setBillingAddress] = useState();
    const [billingAddressError, setBillingAddressError] = useState(false);

    // Billing Contact
    const [billingContact, setBillingContact] = useState([]);

    /*Shipping method*/
    const [shippingMethod, setShippingMethod] = useState("");
    const [shippingMethodError, setShippingMethodError] = useState(false);

    /*Ship with other orders*/
    const [otherOrder, setOtherOrder] = useState([]);
    const [otherOrderError, setOtherOrderError] = useState(false);

    /*Lot Number*/
    const [lotNbr, setLotNbr] = useState("");
    const [lotNbrError, setLotNbrError] = useState(false);
    /*Community*/
    const [community, setCommunity] = useState();
    const [communityError, setCommunityError] = useState(false);
    /*Lot Number*/
    const [jobName, setJobName] = useState("");
    const [jobNameError, setJobNameError] = useState(false);

    /*Lot Number*/
    const [jobNbr, setJobNbr] = useState("");
    const [jobNbrError, setJobNbrError] = useState(false);

    /*Default Controller Price*/
    const [defaultPrice, setDefaultPrice] = useState("");

    /*Address Verification Code*/

    const [addressLevel, setAddressLevel] = useState("");

    /*Installation Country*/
    const [instCountry, setInstCountry] = useState();
    const [instCountryError, setInstCountryError] = useState(false);

    /*Installation State/Province*/
    const [instStateProvince, setInstStateProvince] = useState();
    const [instStateProvinceError, setInstStateProvinceError] = useState(false);

    /*Installation City*/
    const [instCity, setInstCity] = useState("");
    const [instCityError, setInstCityError] = useState(false);

    /*Installation Zip Code*/
    const [instZipCode, setInstZipCode] = useState("");
    const [instZipCodeError, setInstZipCodeError] = useState(false);

    /*Installation Street*/
    const [instStreet, setInstStreet] = useState("");
    const [instStreetError, setInstStreetError] = useState(false);

    /*Installation Street2*/
    const [instStreet2, setInstStreet2] = useState("");

    /*Bulk Inventory Warning*/
    const [shelfInventoryWarning, setShelfInventoryWarning] = useState(false);

    /**complementary PP */
    const [isCompPP, setIsCompPP] = useState(false);

    /*Address Checking section*/
    const [addressChanged, setAddressChanged] = useState(false);
    const [melissaResults, setMelissaResults] = useState([]);
    const [formattedAddress, setFormattedAddress] = useState("");

    /*Modal to warn the user if the requested date is less than 2 weeks in the future*/
    const [dateAlertDialogOpen2, setDateAlertDialogOpen2] = useState(false);
    const [firstRender, setFirstRender] = useState(true);

    /*Ship With Other Goods*/
    const [shipWithOtherGoods, setShipWithOtherGoods] = useState(false);

    //state to open requested delivery date
    const [openDate, setOpenDate] = useState(false);

    const [shipToAddressFlag, setShipToAddressFlag] = useState(false);

    /*Loading*/
    const [loading, setLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [saveError, setSaveError] = useState("");
    const [shipModalOpen, setShipModalOpen] = useState(false);
    const [shipModalUpdatelOpen, setShipModalUpdateOpen] = useState(false);

    const [submitOrderAfterMelissa, setSubmitOrderAfterMelissa] =
      useState(false);

    /*To open modal for display of ship with other orders table*/
    const [openOtherOrders, setOpenOtherOrders] = useState(false);

    const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();

    const [minimumDeliveryDate, setMinimumDeliveryDate] = useState(null);

    /*Field to check is we should call the Address Verification function*/
    const [verifyAddress, setVerifyAddress] = useState(false);

    /*holds updated Order Total*/
    const [updatedOrderTotal, setUpdatedOrderTotal] = useState(null);

    const [melissaInfoSnackbar, setMelissaInfoSnackbar] = useState(false);
    const [melissaMessageSnackbar, setMelissaMessageSnackbar] = useState(false);
    const [melissaSeveritySnackbar, setMelissaSeveritySnackbar] =
      useState(false);

    //Melissa Creadits Error Dialog
    //to display the dialog in case no credits or melissa side error, we give the User to option to continue
    const [melissaCreditsDialog, setMelissaCreaditsDialog] = useState(false);

    //holds addresses returned for autocomplete of addresses
    const [suggestions, setSuggestions] = useState([]);

    //holds autocomplete address

    const [addressValue, setAddressValue] = useState("");
    const [addressPicked, setAddressPicked] = useState(null);
    const [addressVerified, setAddressVerified] = useState(false);

    const history = useHistory();

    //to blur requested delivery date
    var requestedDeliveryDate = React.useRef();

    /*Get current user from Redux*/
    const currentUser = useSelector((state) =>
      state.user.user ? state.user.user.user : {}
    );

    /*Get current user ID from Redux*/
    const currentUserID = useSelector((state) =>
      state.user.user ? state.user.user.user.userID : 0
    );

    /*Get current username from Redux*/
    const currentUserUsername = useSelector((state) =>
      state.user.user ? state.user.user.user.userName : 0
    );

    /*Get agreement permission from redux*/
    const currentCompanyID = useSelector((state) =>
      state.user.user ? state.user.user.user.company.companyID : ""
    );

    /**get complementary project pack permission */
    const canCreateCompPP = useSelector((state) =>
      state.user.user ? state.user.user.user.role.canCreateCompPP : false
    );

    /*Get current company type from redux*/
    const currentCompanyType = useSelector((state) =>
      state.user.user ? state.user.user.user.company.type : ""
    );

    const rolePermissionShippingAddress = useSelector((state) =>
      state.user.user
        ? currentCompanyType !== "L"
          ? state.user.user.user.role.ownCompany
          : state.user.user.user.role.clientCompany
        : ""
    );

    //function to check if the date format is correct
    const handleDateSubmit = async (date) => {
      const regex = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/g;

      if (!regex.test(date)) {
        return "Invalid Date Format";
      }

      const searchDate = moment(date).isValid()
        ? moment(date).format("MM/DD/YYYY")
        : "Invalid Date Format";

      return searchDate;
    };

    const fetchVerifyAddress = async () => {
      systemDataServices
        .getSystemDataVariables()
        .then((response) => {
          setVerifyAddress(response.data.applyAddressValidation);
        })
        .catch((error) => {
          setSaveError(error.response.data.error.error);
        });
    };

    const getRecalculatedOrderTotal = (isCompPP) => {
      /**Function to get the recalculated Order Total when Admin changes Complementary Project Pack flag */

      orderServices
        .getRecalculatedOrderTotal({
          OrderID: order.orderID,
          IsComplementary: isCompPP,
        })
        .then((response) => {
          setUpdatedOrderTotal(response.data);
        })
        .catch((error) => {
          const logDetail = {
            Status: "Function Call",
            Description: `Funciton Call failed for fetching recalulated Order Total when Complementary quick kit flag changed`,
            AdditionalInfo: error.response.data.error.error,
            LogDetailTimeStamp: new Date().toISOString(),
          };

          addLogAndLogDetail(
            "Function Call",
            "Recalculate Order total",
            new Date().toISOString(),
            new Date().toISOString(),
            [logDetail],
            "onError"
          );
          setSaveError(error.response.data.error.error);
        });
    };

    /*Convert UTC to local time*/
    const convertUTC = (utcDate) => {
      var date = new Date(utcDate + "Z");
      var originDate = new Date("January 1, 2000 00:00:00");
      if (date < originDate) {
        return "";
      } else {
        const d = date.toString();
        if (d === "Invalid Date") {
          return "";
        }
        const m = date.toLocaleString("default", { month: "long" });
        return m + " " + d.substring(8, 10) + ", " + d.substring(11, 15);
      }
    };

    const fetchAddresses = (address) => {
      if (verifyAddress === true && instCountry !== null) {
        melissaServices
          .getAddressLookup({
            Address: address,
            Country: instCountry.countryCode,
          })
          .then((response) => {
            setSuggestions(response.data.addresses);
          })
          .catch((error) => {
            const logDetail = {
              Status: "Melissa Error",
              Description: `Address Autocomplete fail, address sent:{Country:${instCountry.description}, Address:${address}}`,
              AdditionalInfo: error.response.data.error.error,
              LogDetailTimeStamp: new Date().toISOString(),
            };

            addLogAndLogDetail(
              "Melissa",
              "Melissa Address Autocomplete",
              new Date().toISOString(),
              new Date().toISOString(),
              [logDetail],
              "onError"
            );
          });
      }
    };

    /*Functions*/
    /*Fetch Agreement data by ID*/
    const fetchOrderDataByID = () => {
      if (
        receivedOrderID !== 0 &&
        receivedOrderID !== null &&
        receivedOrderID !== undefined
      ) {
        setLoading(true);
        orderServices
          .getOrderByID({ OrderID: receivedOrderID })
          .then((response) => {
            const orderData = response.data.order;

            setDefaultPrice(response.data.defaultControllerPrice);
            setOrder(orderData);
            setOrderInTabs(orderData);
            setCompany(orderData.purchaser.company);
            setCompanyTypeForWarningCheck(orderData.purchaser.company.type);
            setPurchaserEmail(orderData.purchaser);

            setOrderType(orderTypeCheck(orderData.type));
            if (orderData.takeoff !== null)
              setAgreement(orderData.takeoff.agreement);
            if (orderData.takeoff !== null) setTakeoff(orderData.takeoff);
            if (orderData.takeoff !== null) setCommunity(orderData.community);
            if (orderData.installerContact !== null)
              setInstallerContact(orderData.installerContact);
            setPurchaseOrderNb(orderData.purchaseOrderNbr);
            setPurchaseOrderNumberInTabs(orderData.purchaseOrderNbr);
            if (orderData.requestedDeliveryDate !== "") {
              setRequestedDate(
                orderData.requestedDeliveryDate !== null
                  ? moment(orderData.requestedDeliveryDate).format("MM/DD/YYYY")
                  : null
              );
              setRequestedDateInTabs(
                orderData.requestedDeliveryDate !== null
                  ? moment(orderData.requestedDeliveryDate).format("MM/DD/YYYY")
                  : null
              );
            }
            setOverDefSwitchColor(orderData.orderSwitchColor);
            setForModelHome(orderData.modelHome);
            setShipToAddress(orderData.shipToAddress);
            setToUpdateShipAddID(
              orderData.shipToAddress ? orderData.shipToAddress.shippingID : 0
            );
            setShippingMethod(
              shipMethodCheckReversed(orderData.shippingMethod)
            );
            setBillingAddress(orderData.billingCompany);
            setLotNbr(orderData.lotNum);
            setJobName(orderData.jobName);
            setJobNumInTabs(orderData.jobNum);
            setJobNbr(orderData.jobNum);
            setOrderStatus(orderData.status);
            setInstCountry(orderData.installationCountry);
            // setInstStateProvince(orderData.installationStateProvince);
            // setInstCity(orderData.installationCity);
            // setInstZipCode(orderData.installationZipPostCode);
            // setInstStreet(orderData.installationStreetAddress);
            // setInstStreet2(orderData.installationSecondStreetAddress);

            setAddressValue(
              `${
                orderData.installationStreetAddress &&
                orderData.installationStreetAddress !== "N/A"
                  ? orderData.installationStreetAddress + ","
                  : ""
              } ${
                orderData.installationCity &&
                orderData.installationCity !== "N/A"
                  ? orderData.installationCity + ","
                  : ""
              } ${
                orderData.installationStateProvince?.stateProvinceCode || ""
              } ${
                orderData.installationZipPostCode &&
                orderData.installationZipPostCode !== "N/A"
                  ? orderData.installationZipPostCode
                  : ""
              }`
            );
            setAddressPicked({
              address: `${orderData.installationStreetAddress || ""},${
                orderData.installationCity || ""
              } ${
                orderData.installationStateProvince?.stateProvinceCode || ""
              } ${orderData.installationZipPostCode || ""}`,
              address1: orderData.installationStreetAddress || "",
              address2: orderData.installationSecondStreetAddress || "",
              city: orderData.installationCity || "",
              zipPostCode: orderData.installationZipPostCode || "",
              country: orderData.installationCountry,
              stateProvince: orderData.installationStateProvince || "",
            });

            setOrderStatusInTabs(orderData.status);
            setSelected(response.data.ordersToBeShippedWith);
            setOrderTotal(response.data.ordersToBeShippedWithTotal);
            setShipWithOtherGoods(orderData.shipWithOtherGoods);
            setSpecialNegotiated(orderData.specialNegotiatedProjectPrice);
            setAddressLevel(orderData.addressVerificationLevel);
            setAddressVerificationLevel(orderData.addressVerificationLevel);
            setIsCompPP(orderData.isComplementaryPP);
          })
          .catch((error) => {
            setSaveError(error.response.data.error.error);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    };

    /*Check for errors*/
    const checkForErrors = async () => {
      const regex = new RegExp("^[0-9]{2}/[0-9]{2}/[0-9]{4}$");

      /*Conditions to check if there are any errors*/
      if (currentCompanyType === "L" && (!company || company === null))
        setCompanyError(true);
      if (orderType === "") setOrderTypeError(true);
      if (
        orderType === "Project Pack for Home" &&
        (!takeoff || takeoff === null)
      )
        setTakeoffError(true);
      if (!purchaserEmail || purchaserEmail === null)
        setPurchaserEmailError(true);
      if (orderType === "Project Pack for Home" && jobNbr === "")
        setJobNbrError(true);
      if (orderType === "Project Pack for Home" && jobName === "")
        setJobNameError(true);
      if (orderType === "Project Pack for Home" && lotNbr === "")
        setLotNbrError(true);

      // if (orderType === "Project Pack for Home" && instStreet === "")
      //   setInstStreetError(true);
      // if (orderType === "Project Pack for Home" && instCity === "")
      //   setInstCityError(true);
      if (
        orderType === "Project Pack for Home" &&
        (!instCountry || instCountry === null)
      )
        setInstCountryError(true);
      // if (
      //   orderType === "Project Pack for Home" &&
      //   (!instStateProvince || instStateProvince === null)
      // )
      //   setInstStateProvinceError(true);
      // if (orderType === "Project Pack for Home" && instZipCode === "")
      //   setInstZipCodeError(true);

      if (
        orderType === "Project Pack for Home" &&
        (addressValue === null || addressValue === "")
      )
        setAddressError(true);

      if (shippingMethod === "" || shippingMethod === null)
        setShippingMethodError(true);
      if (!billingAddress || billingAddress === null)
        setBillingAddressError(true);

      //condition to check if the date format is correct
      if (requestedDate !== null) {
        if (!regex.test(requestedDate)) {
          setRequestedDateError("Invalid Date Format");
        } else {
          const searchDate = moment(requestedDate).isValid()
            ? moment(requestedDate).format("MM/DD/YYYY")
            : "Invalid Date Format";

          if (searchDate === "Invalid Date Format") {
            setRequestedDateError("Invalid Date Format");
          }
        }
      }
      if (
        (orderType === "Project Pack for Home" &&
          (!instCountry || instCountry === null)) ||
        (orderType === "Project Pack for Home" &&
          (addressValue === null || addressValue === "")) ||
        (requestedDate !== null &&
          (regex.test(requestedDate) === false ||
            moment(requestedDate).isValid() === false)) ||
        (currentCompanyType === "L" && (!company || company === null)) ||
        orderType === "" ||
        (orderType === "Project Pack for Home" &&
          (!takeoff || takeoff === null)) ||
        !purchaserEmail ||
        purchaserEmail === null ||
        (orderType === "Project Pack for Home" && jobNbr === "") ||
        (orderType === "Project Pack for Home" && jobName === "") ||
        (orderType === "Project Pack for Home" && lotNbr === "") ||
        (orderType === "Project Pack for Home" &&
          (!instCountry || instCountry === null)) ||
        shippingMethod === "" ||
        shippingMethod === null ||
        !billingAddress ||
        billingAddress === null
      ) {
        return true;
      } else return false;
    };

    useEffect(() => {
      if (melissaSave === true) {
        save("", submitOrderAfterMelissa, true);
        setMelissaSave(false);
      }
    }, [melissaSave]);

    /*Save Changes*/
    const save = async (event, beforeSubmit = false, saveAddress = false) => {
      if (event) event.preventDefault();
      setButtonLoading(true);
      setTabButtonLoading(true);
      /*Conditions to check if there are any errors*/
      const errors = await checkForErrors();
      if (errors) {
        /*If there is any error the function returns and highlights the field containing the error*/
        setButtonLoading(false);
        setTabButtonLoading(false);
        setSaveError("Please Check if All the Fields Are Valid");
        return;
      } else {
        /*If there is no errors*/

        if (addressChanged && !saveAddress && verifyAddress === true) {
          setMelissaMessageSnackbar("Checking This Address...");
          setMelissaSeveritySnackbar("Loading_Icon");
          setMelissaInfoSnackbar(true);
          setButtonLoading(true);
          setTabButtonLoading(true);

          if (
            instCountry.description === "" ||
            instCountry.description === null
          ) {
            setCanSubmitAlert(false);
            setCanSubmitModal(false);
            setButtonLoading(false);
            setTabButtonLoading(false);
            setInstCountryError(true);
            setMelissaMessageSnackbar(
              "Address format not OK, please re-enter."
            );
            setMelissaSeveritySnackbar("Error_Icon");
            setMelissaInfoSnackbar(true);
            return;
          }

          const data = {
            Street1: addressValue,
            Street2: "", //instStreet2,
            City: "",
            StateProvince: "",
            Country: instCountry.description,
            ZipCode: "",
          };

          await melissaServices
            .validateAddress(data)
            .then(async (response) => {
              setMelissaInfoSnackbar(false);
              let descriptions = [];
              let addresslvl = "";

              setFormattedAddress(
                response.data.result.records[0].formattedAddress || ""
              );

              setInstCity(response.data.result.records[0].locality || "N/A");
              setInstCountry(response.data.country);
              setInstStateProvince(response.data.stateProvince || null);
              setInstStreet(
                response.data.result.records[0].addressLine1 || "N/A"
              );
              // if (instStreet2)
              //   setInstStreet2(response.data.result.records[0].addressLine2);

              var zip = response.data.result.records[0].postalCode || "N/A";
              if (zip.indexOf("-") === -1) {
                setInstZipCode(
                  response.data.result.records[0].postalCode || "N/A"
                );
                setAddressValue(
                  `${response.data.result.records[0].addressLine1 || ""}, ${
                    response.data.result.records[0].locality || ""
                  }, ${response.data.stateProvince?.stateProvinceCode || ""} ${
                    response.data.result.records[0].postalCode || ""
                  }`
                );
              } else {
                if (instZipCode.indexOf("-") === -1) {
                  var part1 = zip.split("-");
                  setInstZipCode(part1[0] || "N/A");
                  setAddressValue(
                    `${response.data.result.records[0].addressLine1 || ""}, ${
                      response.data.result.records[0].locality || ""
                    }, ${
                      response.data.stateProvince?.stateProvinceCode || ""
                    } ${part1[0] || ""}`
                  );
                } else {
                  setInstZipCode(
                    response.data.result.records[0].postalCode || "N/A"
                  );
                  setAddressValue(
                    `${response.data.result.records[0].addressLine1 || ""}, ${
                      response.data.result.records[0].locality || ""
                    }, ${
                      response.data.stateProvince?.stateProvinceCode || ""
                    } ${response.data.result.records[0].postalCode || ""}`
                  );
                }
              }

              await response.data.result.records[0].results
                .split(",")
                .forEach((code) => {
                  if (getMelissaLog(code) !== "") {
                    descriptions.push(getMelissaLog(code));
                  }
                  if (
                    code === "AV11" ||
                    code === "AV12" ||
                    code === "AV13" ||
                    code === "AV14" ||
                    code === "AV21" ||
                    code === "AV22" ||
                    code === "AV23" ||
                    code === "AV24" ||
                    code === "AV25"
                  ) {
                    //setAddressVerificationLevel(code);
                    addresslvl = code;
                  }
                });

              setAddressVerificationLevel(addresslvl);

              if (
                addresslvl === "AV11" ||
                addresslvl === "AV12" ||
                addresslvl === "AV13" ||
                addresslvl === "AV14" ||
                addresslvl === "AV21" ||
                addresslvl === "AV22" ||
                addresslvl === "AV23" ||
                addresslvl === "AV24" ||
                addresslvl === "AV25"
              ) {
                setMelissaMessageSnackbar(
                  "Address adjusted/verified, thank you."
                );
                setMelissaSeveritySnackbar("Success_Icon");
                setMelissaInfoSnackbar(true);
                setAddressChanged(false);
                setMelissaSave(true);
              } else {
                setSubmitOrderAfterMelissa(beforeSubmit);
                setMelissaResults(descriptions);
                setMelissaMessageSnackbar(
                  "The address could not be verified at least up to the zip or post code level."
                );
                setMelissaSeveritySnackbar("Warning_Icon");
                setMelissaInfoSnackbar(true);
                setButtonLoading(false);
                setTabButtonLoading(false);
              }
              setCanSubmitAlert(false);
              setCanSubmitModal(false);
            })
            .catch((error) => {
              setCanSubmitAlert(false);
              setCanSubmitModal(false);
              setButtonLoading(false);
              setTabButtonLoading(false);
              if (error.response.data.error.errorCode === "err369") {
                setAddressToLog(`{
                  Address: ${addressValue},
                  Country: ${instCountry.description},
                }`);
                setMelissaCreaditsDialog(true);
                setMelissaMessageSnackbar(
                  "Address format OK but not verifiable at this time. You can proceed with this unverified address."
                );
                setMelissaSeveritySnackbar("Warning_Icon");
                setMelissaInfoSnackbar(true);
              } else {
                setMelissaInfoSnackbar(false);
                setSaveError(error.response.data.error.error);
              }
            });
        } else if (orderType === "Project Pack for Home") {
          if (
            instCountry.description === "" ||
            instCountry.description === null
          ) {
            setCanSubmitAlert(false);
            setCanSubmitModal(false);
            setButtonLoading(false);
            setTabButtonLoading(false);
            setInstCountryError(true);
            setMelissaMessageSnackbar(
              "Address format not OK, please re-enter."
            );
            setMelissaSeveritySnackbar("Error_Icon");
            setMelissaInfoSnackbar(true);
            return;
          }

          let street1 = "";
          let street2 = "";
          let city = "";
          let state = "";
          let zip = "";

          if (addressPicked) {
            //address from autocomplete
            street1 = addressPicked.address1;
            street2 = addressPicked.address2;
            city = addressPicked.city;
            state =
              addressPicked.stateProvince &&
              addressPicked.stateProvince.stateProvinceCode
                ? addressPicked.stateProvince.stateProvinceCode
                : "N/A";
            zip = addressPicked.zipPostCode;
          } else if (verifyAddress) {
            //address not from autocomplete but verification on
            street1 =
              instStreet !== "" && instStreet !== null ? instStreet : "N/A";
            street2 =
              instStreet2 !== "" && instStreet2 !== null ? instStreet2 : "N/A";
            city = instCity !== "" && instCity !== null ? instCity : "N/A";

            state =
              instStateProvince && instStateProvince !== null
                ? instStateProvince.stateProvinceCode
                : "N/A";

            zip =
              instZipCode !== "" && instZipCode !== null ? instZipCode : "N/A";
          } else {
            //address not from autocomplete & verification off
            street1 = addressValue;
          }

          const data = {
            Street1: street1,
            Street2: street2,
            City: city,
            StateProvince: state,
            Country: instCountry.countryCode,
            ZipCode: zip,
          };

          await melissaServices
            .checkDuplicateAddress(data)
            .then((response) => {
              /*If the page is in edit mode*/

              orderServices
                .updateOrder({
                  OrderID: receivedOrderID,
                  PurchaserID:
                    purchaserEmail && purchaserEmail !== null
                      ? purchaserEmail.userID
                      : "",
                  Type:
                    orderType !== ""
                      ? orderType.charAt(0) === "B"
                        ? "S"
                        : orderType.charAt(0)
                      : "",
                  TakeoffID:
                    takeoff && takeoff !== null ? takeoff.takeoffID : "",
                  community: community,
                  InstallerContactID:
                    installerContact && installerContact !== null
                      ? installerContact.userID
                      : "",
                  PurchaseOrderNbr: purchaseOrderNb,
                  RequestedDeliveryDate:
                    requestedDate !== null &&
                    requestedDate !== "" &&
                    requestedDate !== "Invalid Date Format"
                      ? moment(new Date(requestedDate)).format("MM/DD/YYYY")
                      : null,
                  ModelHome: forModelHome,
                  OverrideDefSwitchColorCode:
                    overDefSwitchColor && overDefSwitchColor !== null
                      ? overDefSwitchColor?.switchColorCode
                      : agreement && agreement !== null
                      ? agreement?.defaultCoatedSwitch &&
                        agreement?.defaultCoatedSwitch !== null
                        ? agreement?.defaultCoatedSwitch.switchColorCode
                        : agreement?.defaultSwitch &&
                          agreement?.defaultSwitch !== null
                        ? agreement?.defaultSwitch?.switchColorCode
                        : ""
                      : "",
                  ShipToAddressID:
                    shipToAddress && shipToAddress !== null
                      ? shipToAddress.shippingID
                      : null,
                  ShippingMethod:
                    shippingMethod !== ""
                      ? shipMethodCheck(shippingMethod)
                      : "",
                  BillingCompanyID:
                    billingAddress && billingAddress !== null
                      ? billingAddress.companyID
                      : "",
                  BillingContactID:
                    billingContact && billingContact !== null
                      ? billingContact.userID
                      : null,
                  LotNum: lotNbr,
                  JobName: jobName,
                  JobNum: jobNbr,
                  ModifiedByID: currentUserID,
                  InstallationCity: city,
                  InstallationCountryID:
                    addressPicked !== null && addressPicked !== undefined
                      ? addressPicked.country.countryID
                      : instCountry && instCountry !== null
                      ? instCountry.countryID
                      : null,
                  InstallationStateProvinceID:
                    addressPicked !== null && addressPicked !== undefined
                      ? addressPicked.stateProvince.stateProvinceID
                      : instStateProvince && instStateProvince !== null
                      ? instStateProvince.stateProvinceID
                      : null,
                  InstallationZipPostCode: zip,
                  InstallationStreetAddress: street1,
                  InstallationSecondStreetAddress: street1,
                  Status: currentCompanyType === "L" ? "D" : "N",
                  SpecialNegotiatedProjectPrice: specialNegotiated,
                  OrdersToBeShippedWith: selected,
                  ShipWithOtherGoods: shipWithOtherGoods,
                  AddressVerificationLevel:
                    addressVerified === true
                      ? "AV25"
                      : addressVerificationLevel,
                  IsRMA: isRMA,
                  IsComplementaryPP: isCompPP,
                })
                .then((response) => {
                  setMelissaResults([]);
                  setAddressVerificationLevel("");
                  setFormattedAddress("");
                  setAddressChanged(false);
                  setButtonLoading(false);
                  setTabButtonLoading(false);
                  setFormChanged(false);
                  sessionStorage.removeItem("unsavedChanges");

                  setPristine();
                  if (!beforeSubmit) {
                    setOpenSnackBar(true);
                  }
                  setSaveError("");
                  fetchOrderDataByID(response.data);
                  setDateAlertDialogOpen2(false);
                  if (beforeSubmit) {
                    submitOrderPermission ? submitOrder() : finalizeOrder();
                    setCanSubmitModal(false);
                    setCanSubmitAlert(false);
                  }
                })
                .catch((error) => {
                  setSaveError(error.response.data.error.error);
                  setButtonLoading(false);
                  setTabButtonLoading(false);
                  setDateAlertDialogOpen2(false);
                });
            })
            .catch((error) => {
              setMelissaSave(false);
              setSaveError(error.response.data.error.error);

              setLoading(false);
              setButtonLoading(false);
              setTabButtonLoading(false);
              setDateAlertDialogOpen2(false);
            });
        } else {
          orderServices
            .updateOrder({
              OrderID: receivedOrderID,
              PurchaserID:
                purchaserEmail && purchaserEmail !== null
                  ? purchaserEmail.userID
                  : "",
              Type:
                orderType !== ""
                  ? orderType.charAt(0) === "B"
                    ? "S"
                    : orderType.charAt(0)
                  : "",
              TakeoffID: takeoff && takeoff !== null ? takeoff.takeoffID : "",
              InstallerContactID:
                installerContact && installerContact !== null
                  ? installerContact.userID
                  : "",
              PurchaseOrderNbr: purchaseOrderNb,
              RequestedDeliveryDate:
                requestedDate !== null &&
                requestedDate !== "" &&
                requestedDate !== "Invalid Date Format"
                  ? moment(new Date(requestedDate)).format("MM/DD/YYYY")
                  : null,
              ModelHome: forModelHome,
              OverrideDefSwitchColorCode:
                overDefSwitchColor && overDefSwitchColor !== null
                  ? overDefSwitchColor?.switchColorCode
                  : agreement && agreement !== null
                  ? agreement?.defaultCoatedSwitch &&
                    agreement?.defaultCoatedSwitch !== null
                    ? agreement?.defaultCoatedSwitch.switchColorCode
                    : agreement?.defaultSwitch &&
                      agreement?.defaultSwitch !== null
                    ? agreement?.defaultSwitch?.switchColorCode
                    : ""
                  : "",
              ShipToAddressID:
                shipToAddress && shipToAddress !== null
                  ? shipToAddress.shippingID
                  : null,
              ShippingMethod:
                shippingMethod !== "" ? shipMethodCheck(shippingMethod) : "",
              BillingCompanyID:
                billingAddress && billingAddress !== null
                  ? billingAddress.companyID
                  : "",
              BillingContactID:
                billingContact && billingContact !== null
                  ? billingContact.userID
                  : null,
              LotNum: lotNbr,
              JobName: jobName,
              JobNum: jobNbr,
              ModifiedByID: currentUserID,
              InstallationCity:
                addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.city
                  : instCity,
              InstallationCountryID:
                addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.country.countryID
                  : instCountry && instCountry !== null
                  ? instCountry.countryID
                  : null,
              InstallationStateProvinceID:
                addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.stateProvince.stateProvinceID
                  : instStateProvince && instStateProvince !== null
                  ? instStateProvince.stateProvinceID
                  : null,
              InstallationZipPostCode:
                addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.zipPostCode
                  : instZipCode,
              InstallationStreetAddress:
                addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.address1
                  : instStreet,
              InstallationSecondStreetAddress:
                addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.address2
                  : instStreet2,
              Status: currentCompanyType === "L" ? "D" : "N",
              SpecialNegotiatedProjectPrice: specialNegotiated,
              OrdersToBeShippedWith: selected,
              ShipWithOtherGoods: shipWithOtherGoods,
              AddressVerificationLevel:
                addressVerified === true ? "AV25" : addressVerificationLevel,
              IsRMA: isRMA,
              IsComplementaryPP: isCompPP,
            })
            .then((response) => {
              setMelissaResults([]);
              setAddressVerificationLevel("");
              setFormattedAddress("");
              setAddressChanged(false);
              setButtonLoading(false);
              setTabButtonLoading(false);
              setFormChanged(false);
              sessionStorage.removeItem("unsavedChanges");

              setPristine();
              if (!beforeSubmit) {
                setOpenSnackBar(true);
              }
              setSaveError("");
              fetchOrderDataByID(response.data);
              setDateAlertDialogOpen2(false);
              if (beforeSubmit) {
                submitOrderPermission ? submitOrder() : finalizeOrder();
                setCanSubmitModal(false);
                setCanSubmitAlert(false);
              }
            })
            .catch((error) => {
              setSaveError(error.response.data.error.error);
              setButtonLoading(false);
              setTabButtonLoading(false);
              setDateAlertDialogOpen2(false);
            });
        }
      }
    };

    /*Fetch All companies when there is no company ID received*/
    const fetchAllCompanies = () => {
      companyServices
        .getAllCompanies()
        .then((response) => {
          setCompanies(response.data);
        })
        .catch((error) => {
          setSaveError(error.response.data.error.error);
        });
    };
    /*Get current company type from redux*/
    const currentCompanyIsParent = useSelector((state) =>
      state.user.user ? state.user.user.user.company.isParent : false
    );
    /*Fetch users when there is no company ID received*/
    const fetchCompanyUsers = () => {
      accountServices
        .GetUsersByCompanyIDManageOrder(
          currentCompanyType !== "L" && currentCompanyIsParent !== true
            ? currentCompanyID
            : company.companyID
        )
        .then((response) => {
          setPurchasers(response.data);
          if (currentCompanyType !== "L" && currentCompanyIsParent !== true)
            setPurchaserEmail(currentUser);
        })
        .catch((error) => setSaveError(error.response.data.error.error));
    };

    /*Fetch takeoffs when specifying an agreement*/
    const fetchTakeoffs = () => {
      takeoffServices
        .GetTakeoffByAgreementPurchasing({
          CompanyID: company.companyID,
          CompanyType: company.type,
        })
        .then((response) => {
          setTakeoffs(response.data);
        })
        .catch((error) => {
          setSaveError(error.response.data.error.error);
        });
    };

    /*Fetch Installer's users*/
    const fetchInstallerContacts = () => {
      if (agreement.installer && agreement.installer !== null) {
        accountServices
          .GetUsersByCompanyIDManageOrder(agreement.installer.companyID)
          .then((response) => {
            setInstallerContacts(response.data);
          })
          .catch((error) => setSaveError(error.response.data.error.error));
      }
    };

    /*Fetch Switch Colors*/
    const fetchSwitchColors = () => {
      systemDataServices
        .getAllSwitchColor()
        .then((response) => {
          setSwitchColors(response.data);
        })
        .catch((error) => {
          setSaveError(error.response.data.error.error);
        });
    };

    /*Fetch Def Switch*/
    const fetchDefSwitch = () => {
      if (agreement?.defaultSwitch && agreement?.defaultSwitch !== null) {
        productServices
          .getSwitchesByColorAndStyle({
            Style: agreement.defaultSwitch.switchStyleCode,
            Color:
              overDefSwitchColor && overDefSwitchColor !== null
                ? overDefSwitchColor?.switchColorCode
                : agreement && agreement !== null
                ? agreement.defaultCoatedSwitch &&
                  agreement.defaultCoatedSwitch !== null
                  ? agreement.defaultCoatedSwitch.switchColorCode
                  : agreement.defaultSwitch && agreement.defaultSwitch !== null
                  ? agreement.defaultSwitch.switchColorCode
                  : ""
                : "",
            isCoated:
              agreement && agreement !== null ? agreement.coatedSwitch : "",
          })
          .then((response) => {
            setDefSwitch(response.data[0]);
          })
          .catch((error) => {
            setSaveError(error.response.data.error.error);
          });
      }
    };

    const calculateMaxOrderTotal = (array) => {
      var init = order ? order.orderTotal : 0;

      for (var i = 0; i < array.length; i++) {
        init += array[i].orderTotal;
      }
      setMaxOrderTotal(init);
    };

    /*Fetch unshipped orders*/
    const fetchUnshippedOrder = () => {
      // setSelected([]);
      // setOrderTotal(order ? order.orderTotal : 0);
      // orderServices
      //   .getAllSubmittedOrInProcessOrdersByShippingAddressID(
      //     shipToAddress?.shippingID,
      //     receivedOrderID
      //   )
      //   .then(async (response) => {
      //     setUnshippedOrders(response.data);
      //     await calculateMaxOrderTotal(response.data);
      //   })
      //   .catch((error) => setSaveError(error.response.data.error.error));
    };

    /*Fetch all company shipping addresses*/
    const fetchShippingAddresses = () => {
      shippingaddressServices
        .getAllCompanyShippingAddressesByCompanyID({
          OrderID: receivedOrderID,
          CompanyID: company?.companyID,
        })
        .then((response) => {
          setShippingAddresses(response.data);
        })
        .catch((error) => setSaveError(error.response.data.error.error));
    };

    /*Fetch related users to the specified */
    const fetchRelatedUserToCompany = () => {
      accountServices
        .getAllUsersByRelatedCompanies(company?.companyID)
        .then((response) => {
          setCompaniesUsers(response.data);
        })
        .catch((error) => {
          setSaveError(error.response.data.error.error);
        });
    };

    /*Fetch Company's billing Addresses*/
    const fetchCompanybillingCompanies = () => {
      companyServices
        .getAllRelatedCompaniesByCompanyID(company?.companyID)
        .then(async (response) => {
          await setBillingCompanies(response.data);
          response.data.forEach((address) => {
            if (address?.companyID === company?.companyID)
              setBillingAddress(address);
          });
          /*Fetch the notified of invoices user of a company*/
          await accountServices
            .checkUserNotifInvoices(company?.companyID)
            .then((response) => {
              setBillingContact(response.data);
            })
            .catch((error) => {
              setSaveError(error.response.data.error.error);
            });
        })
        .catch((error) => setSaveError(error.response.data.error.error));
    };

    /*Fetch available countries*/
    const fetchCountries = () => {
      countryServices
        .getCountries()
        .then((response) => {
          setCountries(response.data);
        })
        .catch((error) => {
          setSaveError(error.response.data.message);
        });
    };

    /*Fetch State/Province depending on the chosen country*/
    const fetchStateProvince = (countryID) => {
      countryServices
        .getStateProvince(countryID)
        .then((response) => {
          setStateProvinces(response.data);
        })
        .catch((error) => {
          setSaveError(error.response.data.message);
        });
    };

    /*Check Status*/
    const orderStatusCheck = (status) => {
      if (status === "N") {
        return "Open";
      } else if (status === "D") {
        return "Draft";
      } else if (status === "F") {
        return "Final";
      } else if (status === "T") {
        return "Submitted";
      } else if (status === "I") {
        return "In Process";
      } else if (status === "S") {
        return "Shipped";
      } else if (status === "V") {
        return "Invoiced";
      } else return "";
    };

    /*Check shipping address*/
    const shipMethodCheck = (ship) => {
      if (ship === "Prepaid and charge") {
        return "C";
      } else if (ship === "Prepaid") {
        return "P";
      } else if (ship === "Local pickup") {
        return "L";
      } else if (ship === "Ship with other goods") {
        return "S";
      } else return "";
    };

    /*Check Shipping Method Reversed */
    const shipMethodCheckReversed = (ship) => {
      if (ship === "C") {
        return "Prepaid and charge";
      } else if (ship === "P") {
        return "Prepaid";
      } else if (ship === "L") {
        return "Local pickup";
      } else if (ship === "S") {
        return "Ship with other goods";
      } else return "";
    };

    /*Check Order Type */
    const orderTypeCheck = (ship) => {
      if (ship === "P") {
        return "Project Pack for Home";
      } else if (ship === "S") {
        return "Bulk Inventory";
      } else if (ship === "A") {
        return "Audio Rough-in";
      } else return "";
    };

    /*Check for errors before finalizing an order*/
    const checkBeforeFinalAndSubmit = async (isSubmit) => {
      const errors = await checkForErrors();
      if (errors) {
        /*If there is any error the function returns and highlights the field containing the error*/
        setButtonLoading(false);
        setTabButtonLoading(false);
        setSaveError("Please Check if All the Fields Are Valid");
        return;
      } else {
        if (purchaseOrderNb === "") setPurchaseOrderNbError(true);
        if (!shipToAddress || shipToAddress === null)
          setShipToAddressError(true);
        if (
          requestedDate === "" ||
          requestedDate === null ||
          requestedDate === "Invalid Date Format"
        ) {
          setRequestedDateError(
            isRMA === false
              ? "Requested Delivery Date field is empty."
              : "Delivery Date field is empty"
          );
        }
        if (
          purchaseOrderNb === "" ||
          requestedDate === "" ||
          requestedDate === null ||
          requestedDate === "Invalid Date Format" ||
          !shipToAddress ||
          shipToAddress === null
        ) {
          setSaveError("Please Check if All the Fields Are Valid");
          return;
        } else {
          setSaveError("");

          isSubmit ? setCanSubmitAlert(true) : setCanSubmitModal(true);
        }
      }
    };

    /*fetch Company Data in creation mode when company different than Levven*/
    const fetchCompanyDataById = () => {
      setLoading(true);
      companyServices
        .getCompanyByID(currentCompanyID)
        .then((response) => {
          setCompany(response.data);
        })
        .catch((error) => setSaveError(error.response.data.error.error))
        .finally(() => {
          setLoading(false);
        });
    };

    /*Check if the selected date is in 2 weeks in the future*/
    const checkRequestedDate = async (e) => {
      if (e) e.preventDefault();
      const errors = await checkForErrors();
      if (errors) {
        /*If there is any error the function returns and highlights the field containing the error*/
        setButtonLoading(false);
        setTabButtonLoading(false);
        setSaveError("Please Check if All the Fields Are Valid");
        return;
      } else {
        if (
          requestedDate !== "" &&
          requestedDate !== null &&
          requestedDate !== "Invalid Date Format"
        ) {
          const requested = new Date(requestedDate);
          const minmumRequestedDeliveryDate = new Date(
            Date.now() + minRequestedDeliveryDate
          );
          requested.setHours(0, 0, 0, 0);
          minmumRequestedDeliveryDate.setHours(0, 0, 0, 0);

          if (
            requested < minmumRequestedDeliveryDate &&
            (orderStatus === "N" || orderStatus === "D")
          ) {
            setDateAlertDialogOpen2(true);
            setRequestedDateError(
              "The earliest allowed Request Date is " +
                orderFulfillmentThreshold +
                " day" +
                (orderFulfillmentThreshold === 1 ? "" : "s") +
                " from today."
            );
            setButtonLoading(false);
            return;
          } else {
            save(e, false, false);
          }
        } else save(e, false, false);
      }
    };

    /*Reset All fields on Company change*/
    const resetFields = () => {
      setPurchaserEmail();
      setTakeoff();
      setAgreement();
      setInstallerContact();
      setOverDefSwitchColor();
      setShipToAddress();
      setOtherOrder([]);
      setBillingAddress();
      setBillingContact();
    };

    /*Handle Close Alert Snackbar*/
    const handleCloseAlertSnackbar = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setSaveError("");
    };

    /*Initial useEffect*/
    useEffect(() => {
      if (!create) {
        fetchVerifyAddress();
        fetchOrderDataByID();
      } else {
        if (currentCompanyType === "L") fetchAllCompanies();
        else {
          fetchCompanyDataById();
        }
      }
      fetchSwitchColors();
      fetchCountries();
    }, [orderStatusInTabs, refetchOrderData]);

    /*Everytime the company changes, values and dropdowns options change*/
    useEffect(() => {
      if (company && company !== null) {
        fetchCompanyUsers(company.companyID);
        fetchShippingAddresses();
        fetchCompanybillingCompanies();
        fetchRelatedUserToCompany();
        if (create)
          setShippingMethod(shipMethodCheckReversed(company.shippingMethod));
        if (company.type !== "R") fetchTakeoffs();
        if (company?.type === "R") setOrderType("Bulk Inventory");
        //setInstCountry(company.country);
      } else {
        setShippingMethod("");
      }
    }, [company]);

    /*Everytime the agreement changes, values and dropdowns options changes*/
    useEffect(() => {
      if (agreement && agreement !== null) {
        if (company?.type === "B") fetchInstallerContacts();
        fetchDefSwitch();
      }
    }, [agreement]);

    /*Everytime the override default switch color changes, the default switch changes*/
    useEffect(() => {
      if (
        (overDefSwitchColor && overDefSwitchColor !== null) ||
        currentCompanyType !== "L"
      ) {
        fetchDefSwitch();
      }
    }, [overDefSwitchColor]);

    /*Fetch states/provinces everytime the country changes*/
    useEffect(() => {
      if (instCountry) fetchStateProvince(instCountry.countryID);
    }, [instCountry]);

    useEffect(() => {
      if (shipToAddress && shipToAddress !== null) fetchUnshippedOrder();
    }, [shipToAddress]);

    useEffect(() => {
      /*We make the minimum date pickable 14 days from today*/

      if (minRequestedDeliveryDate !== null) {
        const minimumDaysForRequestedDeliveryDate = new Date(
          Date.now() + minRequestedDeliveryDate
        );

        setMinimumDeliveryDate(minimumDaysForRequestedDeliveryDate);
      }
    }, [minRequestedDeliveryDate]);

    useImperativeHandle(ref, () => ({
      setPristine,
      save,
      checkBeforeFinalAndSubmit,
      checkRequestedDate,
      addressVerificationLevel,
    }));

    /*Handle closing snackBar*/
    const handleSnackBarClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setMelissaInfoSnackbar(false);
    };
    console.log(purchaserEmail);
    console.log(company);
    console.log(company.isParent);
    console.log(currentCompanyType);
    console.log(currentCompanyType !== "L" && company.isParent !== true);
    return (
      <Paper elevation={0}>
        {melissaCreditsDialog === true ? (
          <>
            <AlertDialogSlide
              title="Warning"
              textContent={"Address check failed, cancel or continue anyway?"}
              save={() => {
                const logDetail = {
                  Status:
                    "Melissa Address Check Failed. Melissa tokens may be finished.",
                  Description: `{On Order edit, Job Number: ${jobNbr}, Company Name: ${company?.name}, Purchaser: ${purchaserEmail.userName}}`,
                  AdditionalInfo: AddressToLog,
                  LogDetailTimeStamp: new Date().toISOString(),
                };
                addLogAndLogDetail(
                  "Melissa",
                  "Melissa Address Check Failed",
                  new Date().toISOString(),
                  new Date().toISOString(),
                  [logDetail],
                  "onSave"
                ).then(() => {
                  save("", submitOrderAfterMelissa, true);
                  setMelissaCreaditsDialog(false);
                });
              }}
              saveText="Continue"
              disableUnsave={logLoading === true || buttonLoading === true}
              unsaveLoading={logUnsaveLoading}
              disableSave={logUnsaveLoading === true}
              unsave={() => {
                const logDetail = {
                  Status:
                    "Melissa Address Check Failed. Melissa tokens may be finished.",
                  Description: "",
                  AdditionalInfo: AddressToLog,
                  LogDetailTimeStamp: new Date().toISOString(),
                };
                addLogAndLogDetail(
                  "Melissa",
                  "Melissa Address Check Failed",
                  new Date().toISOString(),
                  new Date().toISOString(),
                  [logDetail],
                  "onUnsave"
                ).then(() => {
                  setMelissaResults([]);
                  setAddressChanged(true);
                  setAddressVerificationLevel("");
                  setMelissaCreaditsDialog(false);
                });
              }}
              unsaveText="Cancel"
              saveButtonLoading={logLoading || buttonLoading}
            />
            <InfoSnackbar
              open={melissaInfoSnackbar}
              message={melissaMessageSnackbar}
              severity={melissaSeveritySnackbar}
              handleClose={handleSnackBarClose}
            />
          </>
        ) : (
          ""
        )}
        {logError !== "" ? (
          <AlertSnackBar
            message={logError}
            handleClose={handleLogCloseError}
            open={logError !== ""}
          />
        ) : null}

        {openOtherOrders ? (
          <>
            <CustomModal openModal={openOtherOrders} width={"60vw"}>
              <Grid
                container
                spacing={3}
                style={{
                  padding: "20px",
                  justifyContent: "center",
                  // textAlign: "justify",
                  textJustify: "inter - word",
                }}
              >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  {openOtherOrders ? (
                    <OtherOrdersTable
                      rows={unshippedOrders}
                      numberRecords={unshippedOrders.length}
                      orderTotal={orderTotal}
                      setOrderTotal={setOrderTotal}
                      initialValue={order ? order.orderTotal : 0}
                      totalValue={maxOrderTotal}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  ) : (
                    <div
                      style={{
                        textAlign: "center",
                        height: "100%",
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress sx={{ color: "#0077c6" }} />
                    </div>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  textAlign="right"
                >
                  <Typography
                    variant="body1"
                    component="span"
                    style={{ marginRight: "30px" }}
                  >
                    {isRMA === false ? "Orders Total:" : "RMAs Total:"}
                  </Typography>
                  <Typography variant="body1" component="span">
                    {orderTotal}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    marginRight: "20px",
                  }}
                >
                  <CustomButton
                    toggle={() => {
                      setOpenOtherOrders(false);
                    }}
                  >
                    Cancel
                  </CustomButton>
                </div>
              </Grid>
            </CustomModal>
          </>
        ) : (
          ""
        )}
        {/*Main Grid*/}
        {loading ? (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="80vh"
          >
            <CircularProgress sx={{ color: "#0077c6" }} />
          </Grid>
        ) : (
          <>
            {/* {Prompt} */}
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={9}
                xl={9}
                sx={{
                  borderRight: "2px solid #c4c4c4",
                  paddingRight: "20px",
                  alignSelf: "flex-start",
                }}
              >
                {/*Form Grid*/}
                <form
                  autoComplete="off"
                  autoCorrect="off"
                  aria-autocomplete="off"
                  onSubmit={create || adminManage ? checkRequestedDate : ""}
                >
                  <Grid container spacing={6}>
                    {create && currentCompanyType === "L" ? (
                      <>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Typography variant="h6" component="span">
                            Company
                          </Typography>
                          <Underline />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
                        {/*Company*/}
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Autocomplete
                            options={companies}
                            value={company ? company : ""}
                            getOptionLabel={(option) =>
                              option.name ? option.name : ""
                            }
                            onInvalid={(event) => {
                              window.scrollTo({
                                top: 1,
                              });
                            }}
                            onChange={(event, value) => {
                              if (create) {
                                setCompany(value);
                                if (!value || value === null) {
                                  setTakeoffs([]);
                                }
                                if (company?.companyID !== value?.companyID) {
                                  resetFields();
                                }
                                if (companyError) setCompanyError(false);
                                setDirty();
                                setFormChanged(true);
                                sessionStorage.setItem(
                                  "unsavedChanges",
                                  JSON.stringify(true)
                                );
                              }
                            }}
                            disabled={create ? false : true}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Company"
                                fullWidth
                                size="small"
                                required
                                error={companyError}
                                helperText={
                                  companyError ? "Company field is empty" : ""
                                }
                                type="text"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "new-password",
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
                      </>
                    ) : null}
                    {orderType !== "Bulk Inventory" ? (
                      <>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Typography variant="h6" component="span">
                            Order Details
                          </Typography>
                          <Underline />
                        </Grid>
                        {/*Status Chip*/}
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          xl={6}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "right",
                          }}
                        >
                          {create ? null : (
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              {order !== null &&
                              order !== undefined &&
                              order.updatedFromNav === true ? (
                                <Tooltip
                                  title={
                                    "This Order has been modified by Levven"
                                  }
                                  arrow
                                >
                                  <IconButton>
                                    <Info color="#98C848" />
                                  </IconButton>
                                </Tooltip>
                              ) : null}
                              <span
                                style={{
                                  padding: "5px 10px",
                                  borderRadius: "25px",
                                  textAlign: "center",
                                  backgroundColor:
                                    orderStatus === "D"
                                      ? "rgba(100, 76, 159, 0.2)"
                                      : orderStatus === "N"
                                      ? "rgba(255, 193, 7, 0.2)"
                                      : orderStatus === "F"
                                      ? "rgba(179, 27, 27 , 0.4)"
                                      : orderStatus === "T"
                                      ? "rgba(84, 184, 122, 0.3)"
                                      : orderStatus === "I"
                                      ? "rgb(64, 184, 194, 0.2)"
                                      : orderStatus === "S"
                                      ? "rgb(28, 128, 0, 0.1)"
                                      : "rgba(150, 150, 150, 0.3)",
                                  color:
                                    orderStatus === "D"
                                      ? "rgba(100, 76, 159, 1)"
                                      : orderStatus === "N"
                                      ? "rgba(255, 193, 7, 1)"
                                      : orderStatus === "F"
                                      ? "rgba(179, 27, 27 , 1)"
                                      : orderStatus === "T"
                                      ? "rgb(84, 184, 122, 1)"
                                      : orderStatus === "I"
                                      ? "rgb(64, 184, 194, 1)"
                                      : orderStatus === "S"
                                      ? "rgb(28, 128, 0, 1)"
                                      : "rgba(150, 150, 150, 1)",
                                }}
                              >
                                {orderStatusCheck(orderStatus)}
                              </span>
                            </div>
                          )}
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Autocomplete
                            id="combo-box-demo"
                            options={orderTypes}
                            value={orderType ? orderType : ""}
                            onChange={(event, value) => {
                              if (value !== "Project Pack for Home") {
                                setTakeoff();
                                setAgreement();
                                setInstallerContact();
                                setOverDefSwitchColor();
                                setForModelHome(false);
                                setSpecialNegotiated(false);
                              }
                              // if (
                              //   company?.type !== "R" &&
                              //   value === "Bulk Inventory"
                              // ) {
                              //   setShelfInventoryWarning(true);
                              // }
                              setOrderType(value);
                              if (orderTypeError) setOrderTypeError(false);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }}
                            onInvalid={(event) => {
                              window.scrollTo({
                                top: 1,
                              });
                            }}
                            disabled={create ? false : true}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Order Type"
                                fullWidth
                                size="small"
                                required
                                error={orderTypeError}
                                helperText={
                                  orderTypeError
                                    ? "Order Type field is empty"
                                    : ""
                                }
                                type="text"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "new-password",
                                }}
                              />
                            )}
                          />
                        </Grid>
                        {/* Takeoff */}
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Autocomplete
                            id="combo-box-demo"
                            options={takeoffs}
                            value={takeoff ? takeoff : ""}
                            getOptionLabel={(option) =>
                              option.name ? option.name : ""
                            }
                            onInvalid={(event) => {
                              window.scrollTo({
                                top: 1,
                              });
                            }}
                            onChange={(event, value) => {
                              setTakeoff(value);
                              if (value && value !== null)
                                setAgreement(value.agreement);
                              if (takeoffError) setTakeoffError(false);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }}
                            disabled={
                              create
                                ? orderType === "Project Pack for Home"
                                  ? false
                                  : true
                                : true
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Takeoff"
                                fullWidth
                                size="small"
                                required={orderType === "Project Pack for Home"}
                                error={takeoffError}
                                helperText={
                                  takeoffError ? "Takeoff field is empty" : ""
                                }
                                type="text"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "new-password",
                                }}
                              />
                            )}
                          />
                        </Grid>

                        {/* Installer Contact Name */}
                        {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Autocomplete
                        id="combo-box-demo"
                        options={installerContacts}
                        getOptionLabel={(option) =>
                          option ? option.firstName + " " + option.lastName : ""
                        }
                        value={installerContact ? installerContact : ""}
                        onChange={(event, value) => {
                          setInstallerContact(value);
                          if (installerContactError)
                            setInstallerContactError(false);
                          setDirty();
                          setFormChanged(true);
                        }}
                        disabled={
                          create || adminManage
                            ? company?.type === "B" &&
                              orderType === "Project Pack for Home"
                              ? false
                              : true
                            : true
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Installer Contact Name"
                            fullWidth
                            size="small"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                            }}
                          />
                        )}
                      />
                    </Grid> */}
                        {/*Special Negotiated Project Price?*/}
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={specialNegotiated}
                                onClick={() => {
                                  if (create || adminManage) {
                                    setSpecialNegotiated(!specialNegotiated);
                                    setDirty();
                                    setFormChanged(true);
                                    sessionStorage.setItem(
                                      "unsavedChanges",
                                      JSON.stringify(true)
                                    );
                                  }
                                }}
                                disabled={
                                  create || adminManage
                                    ? orderType === "Project Pack for Home"
                                      ? agreement && agreement !== null
                                        ? agreement.installer &&
                                          agreement.installer !== null
                                          ? agreement.installer.specialPrice !==
                                              0 &&
                                            agreement.installer.specialPrice !==
                                              null
                                            ? false
                                            : true
                                          : true
                                        : true
                                      : true
                                    : true
                                }
                              />
                            }
                            label="Special Negotiated Project Price?"
                          />
                        </Grid>
                      </>
                    ) : null}

                    {orderType !== "Bulk Inventory" ? (
                      <>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          {canCreateCompPP === true ? (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isCompPP}
                                  onClick={() => {
                                    if (create || adminManage) {
                                      getRecalculatedOrderTotal(!isCompPP);
                                      setIsCompPP(!isCompPP);
                                      setDirty();
                                      setFormChanged(true);
                                      sessionStorage.setItem(
                                        "unsavedChanges",
                                        JSON.stringify(true)
                                      );
                                    }
                                  }}
                                  disabled={
                                    create || adminManage
                                      ? orderType === "Project Pack for Home"
                                        ? false
                                        : true
                                      : true
                                  }
                                />
                              }
                              label="Is this a complementary Project Pack?"
                            />
                          ) : null}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Typography variant="h6" component="span">
                            Job Details
                          </Typography>
                          <Underline />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
                        {/*Job Name */}
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            variant="outlined"
                            size="small"
                            label="Job Name"
                            fullWidth
                            value={jobName}
                            onInvalid={(event) => {
                              window.scrollTo({
                                top: 1,
                              });
                            }}
                            inputProps={{
                              autoComplete: "new-password",
                            }}
                            error={jobNameError}
                            helperText={
                              jobNameError ? "Job Name field is empty" : ""
                            }
                            onChange={(e) => {
                              setJobName(e.target.value);
                              if (jobNameError) setJobNameError(false);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }}
                            required={orderType === "Project Pack for Home"}
                            disabled={
                              create || adminManage
                                ? orderType === "Project Pack for Home"
                                  ? false
                                  : true
                                : true
                            }
                          />
                        </Grid>
                        {/*Job Number */}
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            variant="outlined"
                            size="small"
                            label="Job Number"
                            fullWidth
                            value={jobNbr}
                            inputProps={{
                              maxLength: 30,
                              autoComplete: "new-password",
                            }}
                            error={jobNbrError}
                            helperText={
                              jobNbrError ? "Job Name field is empty" : ""
                            }
                            onChange={(e) => {
                              setJobNbr(e.target.value);
                              if (jobNbrError) setJobNbrError(false);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }}
                            required={orderType === "Project Pack for Home"}
                            onInvalid={(event) => {
                              window.scrollTo({
                                top: 1,
                              });
                            }}
                            disabled={
                              create || adminManage
                                ? orderType === "Project Pack for Home"
                                  ? false
                                  : true
                                : true
                            }
                          />
                        </Grid>
                        {/*Lot Number */}
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            variant="outlined"
                            size="small"
                            label="Lot Number"
                            fullWidth
                            value={lotNbr}
                            inputProps={{
                              autoComplete: "new-password",
                            }}
                            error={lotNbrError}
                            helperText={
                              lotNbrError ? "Lot Number field is empty" : ""
                            }
                            onChange={(e) => {
                              setLotNbr(e.target.value);
                              if (lotNbrError) setLotNbrError(false);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }}
                            required={orderType === "Project Pack for Home"}
                            onInvalid={(event) => {
                              window.scrollTo({
                                top: 1,
                              });
                            }}
                            disabled={
                              create || adminManage
                                ? orderType === "Project Pack for Home"
                                  ? false
                                  : true
                                : true
                            }
                          />
                        </Grid>
                        {/* Community  */}
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            variant="outlined"
                            size="small"
                            label="Community "
                            fullWidth
                            value={community}
                            inputProps={{
                              autoComplete: "new-password",
                            }}
                            error={communityError}
                            helperText={
                              communityError ? "Community field is empty" : ""
                            }
                            onChange={(e) => {
                              setCommunity(e.target.value);
                              if (communityError) setCommunityError(false);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }}
                            // required={orderType === "Project Pack for Home"}
                            onInvalid={(event) => {
                              window.scrollTo({
                                top: 1,
                              });
                            }}
                            disabled={
                              create || adminManage
                                ? orderType === "Project Pack for Home"
                                  ? false
                                  : true
                                : true
                            }
                          />
                        </Grid>
                      </>
                    ) : null}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Typography variant="h6" component="span">
                        {isRMA === false
                          ? "Purchasing Information"
                          : "RMA Information"}
                      </Typography>
                      <Underline />
                    </Grid>
                    {/*Status Chip*/}
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "right",
                      }}
                    >
                      {!create && orderType === "Bulk Inventory" ? (
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          {order !== null &&
                          order !== undefined &&
                          order.updatedFromNav === true ? (
                            <Tooltip
                              title={"This Order has been modified by Levven"}
                              arrow
                            >
                              <IconButton>
                                <Info color="#98C848" />
                              </IconButton>
                            </Tooltip>
                          ) : null}
                          <span
                            style={{
                              padding: "5px 10px",
                              borderRadius: "25px",
                              textAlign: "center",
                              backgroundColor:
                                orderStatus === "D"
                                  ? "rgba(100, 76, 159, 0.2)"
                                  : orderStatus === "N"
                                  ? "rgba(255, 193, 7, 0.2)"
                                  : orderStatus === "F"
                                  ? "rgba(179, 27, 27 , 0.4)"
                                  : orderStatus === "T"
                                  ? "rgba(84, 184, 122, 0.3)"
                                  : orderStatus === "I"
                                  ? "rgb(64, 184, 194, 0.2)"
                                  : orderStatus === "S"
                                  ? "rgb(28, 128, 0, 0.1)"
                                  : "rgba(150, 150, 150, 0.3)",
                              color:
                                orderStatus === "D"
                                  ? "rgba(100, 76, 159, 1)"
                                  : orderStatus === "N"
                                  ? "rgba(255, 193, 7, 1)"
                                  : orderStatus === "F"
                                  ? "rgba(179, 27, 27 , 1)"
                                  : orderStatus === "T"
                                  ? "rgb(84, 184, 122, 1)"
                                  : orderStatus === "I"
                                  ? "rgb(64, 184, 194, 1)"
                                  : orderStatus === "S"
                                  ? "rgb(28, 128, 0, 1)"
                                  : "rgba(150, 150, 150, 1)",
                            }}
                          >
                            {orderStatusCheck(orderStatus)}
                          </span>
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <TextField
                        variant="outlined"
                        size="small"
                        label={
                          isRMA === false
                            ? "Purchase Order Number"
                            : "Service Case/Ticket Number"
                        }
                        fullWidth
                        value={purchaseOrderNb}
                        error={purchaseOrderNbError || highlightPO}
                        helperText={
                          purchaseOrderNbError || highlightPO
                            ? isRMA === false
                              ? "Purchase Order Number field is empty"
                              : "Service Case/Ticket Number field is empty"
                            : isRMA === false
                            ? `A PO number is required before this order can be sent to Levven. You can save this order as a draft and enter a PO number later.`
                            : `A Service Case/Ticket Number is required before this RMA can be sent to Levven. You can save this RMA as a draft and enter a Service Case/Ticket Number later.`
                        }
                        onChange={(e) => {
                          if (create || adminManage) {
                            setPurchaseOrderNb(e.target.value);
                            if (purchaseOrderNbError)
                              setPurchaseOrderNbError(false);
                            if (highlightPO) setHighlightPO(false);
                            setDirty();
                            setFormChanged(true);
                            sessionStorage.setItem(
                              "unsavedChanges",
                              JSON.stringify(true)
                            );
                          }
                        }}
                        disabled={create || adminManage ? false : true}
                        inputProps={{
                          autoComplete: "new-password",
                          maxLength: 35,
                        }}
                      />
                    </Grid>
                    {/* Requested Date */}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <CustomDatePickerComponent
                        label={
                          isRMA === false
                            ? "Requested Delivery Date"
                            : "Delivery Date"
                        }
                        helperText={
                          "The earliest allowed " +
                          (isRMA === false ? "Request" : "Delivery") +
                          " Date is " +
                          orderFulfillmentThreshold +
                          " day" +
                          (orderFulfillmentThreshold === 1 ? "" : "s") +
                          " from today."
                        }
                        value={requestedDate}
                        isRMA={isRMA}
                        setValue={setRequestedDate}
                        inOrder={true}
                        setDateInTabs={setRequestedDateInTabs}
                        defaultCalendarMonth={minimumDeliveryDate}
                        minDate={minimumDeliveryDate}
                        setDirty={setDirty}
                        setFormChanged={setFormChanged}
                        disabled={create || adminManage ? false : true}
                        error={requestedDateError}
                        setError={setRequestedDateError}
                        error2={highlightDate}
                        setError2={setHighlightDate}
                      />
                    </Grid>
                    {/* Override Default Switch Color? */}
                    {orderType !== "Bulk Inventory" ? (
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Autocomplete
                          id="combo-box-demo"
                          options={switchColors}
                          getOptionLabel={(option) =>
                            option
                              ? option.switchColorCode ===
                                agreement?.defaultSwitchColorCode
                                ? option.description + " (default)"
                                : option.description
                              : ""
                          }
                          value={overDefSwitchColor ? overDefSwitchColor : ""}
                          onChange={(event, value) => {
                            setOverDefSwitchColor(value);
                            setDirty();
                            setFormChanged(true);
                            sessionStorage.setItem(
                              "unsavedChanges",
                              JSON.stringify(true)
                            );
                          }}
                          disabled={
                            create || adminManage
                              ? orderType === "Project Pack for Home"
                                ? false
                                : true
                              : true
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Override Builder’s Default Switch Color?"
                              fullWidth
                              size="small"
                              type="text"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password",
                              }}
                            />
                          )}
                        />
                      </Grid>
                    ) : null}
                    {orderType !== "Bulk Inventory" ? (
                      <>
                        {/*Is this order for a model home?*/}
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={forModelHome}
                                onClick={() => {
                                  if (create || adminManage) {
                                    setForModelHome(!forModelHome);
                                    setDirty();
                                    setFormChanged(true);
                                    sessionStorage.setItem(
                                      "unsavedChanges",
                                      JSON.stringify(true)
                                    );
                                  }
                                }}
                                disabled={
                                  create || adminManage
                                    ? orderType === "Project Pack for Home"
                                      ? false
                                      : true
                                    : true
                                }
                              />
                            }
                            label="Is this order for a Model Home?"
                          />
                        </Grid>
                      </>
                    ) : null}
                    {orderType !== "Bulk Inventory" ? (
                      <>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography variant="h6" component="span">
                              Installation Address
                            </Typography>
                            <Tooltip
                              title={
                                addressLevel === "AV11" ||
                                addressLevel === "AV12" ||
                                addressLevel === "AV13" ||
                                addressLevel === "AV14"
                                  ? "Address Partially Verified"
                                  : addressLevel === "AV21" ||
                                    addressLevel === "AV22" ||
                                    addressLevel === "AV23" ||
                                    addressLevel === "AV24" ||
                                    addressLevel === "AV25"
                                  ? "Address Fully Verified"
                                  : "Address Not Verified"
                              }
                            >
                              <span>
                                <IconButton disabled>
                                  <Compass
                                    color={
                                      addressLevel === "AV11" ||
                                      addressLevel === "AV12" ||
                                      addressLevel === "AV13" ||
                                      addressLevel === "AV14"
                                        ? "#FEBF11"
                                        : addressLevel === "AV21" ||
                                          addressLevel === "AV22" ||
                                          addressLevel === "AV23" ||
                                          addressLevel === "AV24" ||
                                          addressLevel === "AV25"
                                        ? "#98C848"
                                        : "#E63846"
                                    }
                                  />
                                </IconButton>
                              </span>
                            </Tooltip>
                          </div>
                          <Underline />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Autocomplete
                            id="combo-box-demo"
                            options={countries}
                            value={instCountry ? instCountry : ""}
                            getOptionLabel={(option) =>
                              option ? option.description : ""
                            }
                            onChange={(e, value) => {
                              if (
                                instCountry?.description !== value?.description
                              ) {
                                setInstStateProvince();
                              }
                              setInstCountry(value);
                              setAddressValue("");
                              setAddressPicked(null);

                              if (instCountryError) setInstCountryError(false);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                              setAddressChanged(true);
                            }}
                            disabled={
                              create || adminManage
                                ? orderType === "Project Pack for Home"
                                  ? false
                                  : true
                                : true
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Country"
                                fullWidth
                                size="small"
                                error={instCountryError}
                                helperText={
                                  instCountryError
                                    ? "Installation Country field is empty"
                                    : ""
                                }
                                type="text"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "new-password",
                                }}
                                required={orderType === "Project Pack for Home"}
                                onInvalid={(event) => {
                                  window.scrollTo({
                                    top: 1,
                                  });
                                }}
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Autocomplete
                            id="free-solo-demo"
                            freeSolo
                            autoSelect
                            options={suggestions.map(
                              (options) =>
                                (options.address1 && options.address1 !== "N/A"
                                  ? options.address1 + ", "
                                  : "") +
                                (options.city && options.city !== "N/A"
                                  ? options.city + ", "
                                  : "") +
                                (options.stateProvince &&
                                options.stateProvince.stateProvinceCode
                                  ? options.stateProvince.stateProvinceCode +
                                    " "
                                  : "") +
                                (options.zipPostCode &&
                                options.zipPostCode !== "N/A"
                                  ? options.zipPostCode
                                  : "")
                            )}
                            value={addressValue}
                            disabled={
                              instCountry === null || instCountry === undefined
                            }
                            onChange={(event, newValue) => {
                              if (verifyAddress === true)
                                fetchAddresses(newValue);
                              if (addressError) setAddressError(false);
                              var index = suggestions.findIndex(
                                (x) =>
                                  x.address1 +
                                    ", " +
                                    x.city +
                                    ", " +
                                    x.stateProvince.stateProvinceCode +
                                    " " +
                                    x.zipPostCode ===
                                  newValue
                              );

                              if (index !== -1) {
                                setAddressChanged(false);
                                setAddressPicked(suggestions[index]);

                                setAddressValue(
                                  suggestions[index].address1 +
                                    ", " +
                                    suggestions[index].city +
                                    ", " +
                                    suggestions[index].stateProvince
                                      .stateProvinceCode +
                                    " " +
                                    suggestions[index].zipPostCode
                                );
                                setAddressVerified(true);
                              } else {
                                setAddressVerified(false);
                                setAddressPicked(null);
                                setAddressChanged(true);
                                setAddressValue(newValue);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Address"
                                size="small"
                                required
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "new-password",
                                }}
                                error={addressError}
                                onChange={(newValue) => {
                                  if (verifyAddress === true)
                                    fetchAddresses(newValue.target.value);
                                }}
                                helperText={
                                  addressError
                                    ? "Address field is empty. Address Format: [Address1], [City], [State Province] [Zip Post Code]"
                                    : "Address Format: [Address1], [City], [State Province] [Zip Post Code]"
                                }
                                disabled={
                                  instCountry === null ||
                                  instCountry === undefined
                                }
                              />
                            )}
                          />
                        </Grid>
                      </>
                    ) : null}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="h6" component="span">
                          Shipping Address
                        </Typography>
                        {rolePermissionShippingAddress === "M" ? (
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Tooltip
                              arrow
                              title="Edit Selected Shipping Address"
                            >
                              <IconButton
                                disabled={
                                  create || adminManage
                                    ? toUpdateShipAddID === 0
                                    : true
                                }
                              >
                                <Edit
                                  onClick={() => {
                                    setShipModalUpdateOpen(true);
                                  }}
                                  style={{ cursor: "pointer" }}
                                />
                              </IconButton>
                            </Tooltip>
                            <Tooltip arrow title="Add a New Shipping Address">
                              <IconButton
                                disabled={
                                  create || adminManage
                                    ? !company || company === null
                                    : true
                                }
                              >
                                <PlusCircle
                                  onClick={() => setShipModalOpen(true)}
                                  style={{ cursor: "pointer" }}
                                />
                              </IconButton>
                            </Tooltip>
                          </div>
                        ) : null}
                      </div>
                      <Underline />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
                    {/* Ship to Address */}
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Tooltip
                        arrow
                        title={
                          shipToAddress && shipToAddress !== null
                            ? (shipToAddress.name !== null
                                ? shipToAddress.name + " - "
                                : " ") +
                              shipToAddress?.streetAddress +
                              (shipToAddress?.secondAddress &&
                              shipToAddress?.secondAddress !== null
                                ? ", " + shipToAddress?.secondAddress
                                : "") +
                              ", " +
                              shipToAddress?.city +
                              ", " +
                              shipToAddress?.stateProvince?.description +
                              ", " +
                              shipToAddress?.country?.description +
                              ", " +
                              shipToAddress?.zipPostCode
                            : ""
                        }
                        componentsProps={{
                          tooltip: {
                            sx: {
                              maxWidth: "50vw",
                              lineHeight: "15px",
                            },
                          },
                        }}
                      >
                        <Autocomplete
                          id="combo-box-demo"
                          options={
                            shippingAddresses.length !== 0 ||
                            ((create || adminManage) && company)
                              ? rolePermissionShippingAddress === "M"
                                ? [
                                    ...shippingAddresses,
                                    {
                                      added: true,
                                      description: "Add New Shipping Address",
                                      display:
                                        (create || adminManage) && company,
                                    },
                                  ]
                                : [...shippingAddresses]
                              : [
                                  {
                                    added: true,
                                    description: "No Options",
                                    display:
                                      shippingAddresses.length === 0 &&
                                      !((create || adminManage) && company),
                                  },
                                ]
                          }
                          getOptionLabel={(option) =>
                            option
                              ? !option.added
                                ? (option.name !== null
                                    ? option.name + " - "
                                    : " ") +
                                  option?.streetAddress +
                                  (option?.secondAddress &&
                                  option?.secondAddress !== null
                                    ? ", " + option?.secondAddress
                                    : "") +
                                  ", " +
                                  option?.city +
                                  ", " +
                                  option?.stateProvince?.description +
                                  ", " +
                                  option?.country?.description +
                                  ", " +
                                  option?.zipPostCode
                                : option.display
                                ? option.description
                                : ""
                              : ""
                          }
                          value={shipToAddress ? shipToAddress : ""}
                          onChange={(event, value) => {
                            if (value) {
                              if (value.added) {
                                if (value.description === "No Options")
                                  setShipToAddressFlag(true);
                                else setShipModalOpen(true);
                              } else {
                                setShipToAddress(value);
                                if (value && value !== null)
                                  setToUpdateShipAddID(value.shippingID);
                                else setToUpdateShipAddID(0);
                                if (shipToAddressError)
                                  setShipToAddressError(false);
                              }
                            } else {
                              setShipToAddress(value);
                              if (value && value !== null)
                                setToUpdateShipAddID(value.shippingID);
                              else setToUpdateShipAddID(0);
                              if (shipToAddressError)
                                setShipToAddressError(false);
                            }
                            setDirty();
                            setFormChanged(true);
                            sessionStorage.setItem(
                              "unsavedChanges",
                              JSON.stringify(true)
                            );
                          }}
                          disabled={create || adminManage ? false : true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Ship to Address"
                              fullWidth
                              size="small"
                              onInvalid={(event) => {
                                window.scrollTo({
                                  top: 1,
                                });
                              }}
                              onKeyDown={(e) => {
                                if (e.code !== "Backspace") {
                                  e.preventDefault();
                                }
                              }}
                              onKeyPress={(e) => {
                                e.preventDefault();
                              }}
                              error={shipToAddressError}
                              helperText={
                                shipToAddressError
                                  ? "Ship to Address field is empty"
                                  : ""
                              }
                              type="text"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password",
                              }}
                            />
                          )}
                        />
                      </Tooltip>
                    </Grid>
                    {/* Shipping Method */}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Autocomplete
                        id="combo-box-demo"
                        options={shippingMethods}
                        value={shippingMethod ? shippingMethod : ""}
                        onChange={(event, value) => {
                          setShippingMethod(value);
                          if (value === "Local pickup")
                            setShipWithOtherGoods(false);
                          if (shippingMethodError)
                            setShippingMethodError(false);
                          setDirty();
                          setFormChanged(true);
                          sessionStorage.setItem(
                            "unsavedChanges",
                            JSON.stringify(true)
                          );
                        }}
                        onInvalid={(event) => {
                          window.scrollTo({
                            top: 1,
                          });
                        }}
                        disabled={
                          company === null ||
                          company === undefined ||
                          (create === false && adminManage === false) ||
                          company.shippingMethod !== "L"
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Shipping Method"
                            fullWidth
                            size="small"
                            error={shippingMethodError}
                            helperText={
                              shippingMethodError
                                ? "Shipping Method field is empty"
                                : ""
                            }
                            required
                            onInvalid={(event) => {
                              window.scrollTo({
                                top: 1,
                              });
                            }}
                            type="text"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                            }}
                          />
                        )}
                      />
                    </Grid>

                    {/*Ship With Other Goods*/}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={shipWithOtherGoods}
                            onClick={() => {
                              setShipWithOtherGoods(!shipWithOtherGoods);
                              setDirty();
                            }}
                            disabled={
                              create || adminManage
                                ? shippingMethod === "Local pickup"
                                  ? true
                                  : false
                                : true
                            }
                          />
                        }
                        label="Ship with other goods"
                      />
                    </Grid>
                    {/* <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          margin: "auto 0",
                        }}
                      >
                        <Typography variant="body1" component="span">
                          Select Other Orders:
                        </Typography>
                        <IconButton
                          style={{ marginLeft: "30px" }}
                          onClick={() => setOpenOtherOrders(true)}
                        >
                          {" "}
                          <Pocket />
                        </IconButton>
                      </div> */}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Typography variant="h6" component="span">
                        Billing Address
                      </Typography>
                      <Underline />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
                    {/* Purchasing email */}
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Autocomplete
                        id="combo-box-demo"
                        options={purchasers}
                        getOptionLabel={(option) =>
                          option ? option.userName : ""
                        }
                        value={purchaserEmail ? purchaserEmail : ""}
                        onChange={(event, value) => {
                          setPurchaserEmail(value);
                          if (purchaserEmailError)
                            setPurchaserEmailError(false);
                          setDirty();
                          setFormChanged(true);
                          sessionStorage.setItem(
                            "unsavedChanges",
                            JSON.stringify(true)
                          );
                        }}
                        disabled={
                          create
                            ? currentCompanyType === "L"
                              ? false
                              : true
                            : true
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Purchasing Email"
                            fullWidth
                            size="small"
                            required
                            onInvalid={(event) => {
                              window.scrollTo({
                                top: 1,
                              });
                            }}
                            error={purchaserEmailError}
                            helperText={
                              purchaserEmailError
                                ? "Purchasing Email field is empty"
                                : ""
                            }
                            type="text"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                            }}
                          />
                        )}
                      />
                    </Grid>
                    {/* Billing Address */}
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      {/* <Tooltip
                        arrow
                        title={
                          billingAddress && billingAddress !== null
                            ? 
                              billingAddress?.streetAddress +
                              (billingAddress?.secondAddress &&
                              billingAddress?.secondAddress !== null
                                ? ", " + billingAddress?.secondAddress
                                : "") +
                              ", " +
                              billingAddress?.city +
                              ", " +
                              billingAddress?.stateProvince?.description +
                              ", " +
                              billingAddress?.country?.description +
                              ", " +
                              billingAddress?.zipPostCode
                            : ""
                        }
                        componentsProps={{
                          tooltip: {
                            sx: {
                              maxWidth: "50vw",
                              lineHeight: "15px",
                            },
                          },
                        }}
                      >
                        <Autocomplete
                          id="combo-box-demo"
                          options={billingCompanies}
                          getOptionLabel={(option) =>
                            option
                              ? option?.streetAddress +
                                (option?.secondAddress &&
                                option?.secondAddress !== null
                                  ? ", " + option?.secondAddress
                                  : "") +
                                ", " +
                                option?.city +
                                ", " +
                                option?.stateProvince?.description +
                                ", " +
                                option?.country?.description +
                                ", " +
                                option?.zipPostCode
                              : ""
                          }
                          value={billingAddress ? billingAddress : ""}
                          onChange={(event, value) => {
                            setBillingAddress(value);
                            if (billingAddressError)
                              setBillingAddressError(false);
                            setDirty();
                            setFormChanged(true);
                          }}
                          disabled
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Billing Address"
                              fullWidth
                              required
                              size="small"
                              error={billingAddressError}
                              helperText={
                                shipToAddressError
                                  ? "Billing Address field is empty"
                                  : ""
                              }
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password",
                              }}
                            />
                          )}
                        />
                      </Tooltip> */}
                      <TextField
                        multiline
                        label="Billing Address"
                        fullWidth
                        disabled
                        value={
                          billingAddress && billingAddress !== null
                            ? billingAddress?.streetAddress +
                              (billingAddress?.secondAddress &&
                              billingAddress?.secondAddress !== null
                                ? ", " + billingAddress?.secondAddress
                                : "") +
                              ", " +
                              billingAddress?.city +
                              ", " +
                              billingAddress?.stateProvince?.description +
                              ", " +
                              billingAddress?.country?.description +
                              ", " +
                              billingAddress?.zipPostCode
                            : ""
                        }
                      />
                    </Grid>
                    {/*Save button*/}

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className={classes.buttonStyle5}
                    >
                      {create || adminManage ? (
                        <>
                          {orderStatusInTabs === "F" &&
                          submitOrderPermission ? (
                            <>
                              <CustomButton
                                startIcon={<BookOpen />}
                                loading={reopenLoading}
                                toggle={() => setReopenDialog(true)}
                              >
                                Reopen
                              </CustomButton>
                              <div className={classes.divStyle5} />
                            </>
                          ) : null}

                          {orderStatusInTabs === "N" ||
                          orderStatusInTabs === "D" ||
                          orderStatusInTabs === "F" ? (
                            <>
                              <>
                                <CustomButton
                                  startIcon={<Send />}
                                  loading={submitLoading}
                                  toggle={() => {
                                    checkBeforeFinalAndSubmit(
                                      submitOrderPermission ? true : false
                                    );
                                  }}
                                >
                                  {submitOrderPermission
                                    ? isRMA === false
                                      ? "Place Order"
                                      : "Place RMA"
                                    : "Send to Purchasing for Approval"}
                                </CustomButton>

                                {orderStatusInTabs !== "F" ? (
                                  <>
                                    <div className={classes.divStyle5} />
                                    <CustomButton
                                      type="submit"
                                      loading={buttonLoading}
                                      startIcon={<Save />}
                                    >
                                      Save
                                    </CustomButton>
                                  </>
                                ) : null}
                              </>
                            </>
                          ) : null}

                          {orderStatusInTabs === "N" ||
                          orderStatusInTabs === "D" ? (
                            <>
                              <div className={classes.divStyle5} />
                              <CustomButton
                                startIcon={<Trash2 />}
                                loading={loading}
                                toggle={() => setDeleteRecord(true)}
                              >
                                Delete
                              </CustomButton>
                            </>
                          ) : null}
                        </>
                      ) : null}
                      <>
                        {getError === "" &&
                        rolePermission === "M" &&
                        (orderStatusInTabs === "T" ||
                          orderStatusInTabs === "I" ||
                          orderStatusInTabs === "S") &&
                        isRMA === false ? (
                          <>
                            <CustomButton
                              startIcon={<Phone />}
                              toggle={() => {
                                setOpenLevvenContactsModal(true);
                                getOrderModificationLevvenContactText();
                              }}
                            >
                              {isRMA === false ? "Edit Order" : "Edit RMA"}
                            </CustomButton>
                          </>
                        ) : null}
                        <div className={classes.divStyle5} />
                        <CustomButton
                          startIcon={<X />}
                          toggle={async () => {
                            await setFormChanged(false);
                            await sessionStorage.removeItem("unsavedChanges");
                            await setPristine();
                            await history.push(
                              isRMA === false ? "/orders" : "/RMA"
                            );
                          }}
                        >
                          Cancel
                        </CustomButton>
                      </>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}></Grid>
                  </Grid>
                </form>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={3}
                xl={3}
                sx={{
                  paddingLeft: "20px",
                  alignSelf: "flex-start",
                }}
              >
                <Paper
                  elevation={0}
                  sx={{
                    border: "1px solid #c4c4c4",
                    padding: "20px",
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography variant="subtitle2" component="span">
                        Purchasing Company Details
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography
                        variant="caption"
                        fontWeight={500}
                        component="span"
                      >
                        Company:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography variant="caption" component="span">
                        {company && company !== null ? company.name : ""}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography
                        variant="caption"
                        fontWeight={500}
                        component="span"
                      >
                        Contact:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography variant="caption" component="span">
                        {purchaserEmail
                          ? purchaserEmail.firstName +
                            " " +
                            purchaserEmail.lastName
                          : ""}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography
                        variant="caption"
                        fontWeight={500}
                        component="span"
                      >
                        Contact Phone Number:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography variant="caption" component="span">
                        {purchaserEmail ? purchaserEmail.phoneNumber : ""}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography
                        variant="caption"
                        fontWeight={500}
                        component="span"
                      >
                        Levven Sales Order Number:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography
                        variant="caption"
                        fontWeight={500}
                        component="span"
                      >
                        {order && order !== null ? order.navNo : ""}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography
                        variant="caption"
                        fontWeight={500}
                        component="span"
                      >
                        Currency:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography variant="caption" component="span">
                        {company && company !== null ? company.country.cur : ""}
                      </Typography>
                    </Grid>

                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography
                        variant="caption"
                        fontWeight={500}
                        component="span"
                      >
                        {isRMA === false ? "Order Total:" : "RMA Total:"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography variant="caption" component="span">
                        {order && order !== null
                          ? (company?.country?.cur === "USD" ? "$" : "C$") +
                            " " +
                            (updatedOrderTotal !== null
                              ? parseFloat(updatedOrderTotal).toFixed(2)
                              : parseFloat(order.orderTotal).toFixed(2))
                          : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
                {orderType === "Project Pack for Home" ? (
                  <>
                    <Paper
                      elevation={0}
                      sx={{
                        border: "1px solid #c4c4c4",
                        padding: "20px",
                        marginTop: "20px",
                      }}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Typography variant="subtitle2" component="span">
                            Agreement
                          </Typography>
                        </Grid>
                        {orderType !== "Bulk Inventory" ? (
                          <>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                              <Typography
                                variant="caption"
                                fontWeight={500}
                                component="span"
                              >
                                Agreement Name:
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                              <Typography variant="caption" component="span">
                                {agreement && agreement !== null
                                  ? agreement.name
                                  : ""}
                              </Typography>
                            </Grid>
                          </>
                        ) : null}
                        {company?.type !== "B" ? null : (
                          <>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                              <Typography
                                variant="caption"
                                fontWeight={500}
                                component="span"
                              >
                                Installer:
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                              <Typography variant="caption" component="span">
                                {agreement && agreement !== null
                                  ? agreement.installer &&
                                    agreement.installer !== null
                                    ? agreement.installer.name
                                    : ""
                                  : ""}
                              </Typography>
                            </Grid>
                            {installerContact && installerContact !== null ? (
                              <>
                                <Grid item xs={6} sm={6} md={6} lg={12} xl={12}>
                                  <Typography
                                    variant="caption"
                                    fontWeight={500}
                                    component="span"
                                  >
                                    Installer Purchasing Email:
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={12} xl={12}>
                                  <Typography
                                    variant="caption"
                                    component="span"
                                  >
                                    {installerContact &&
                                    installerContact !== null
                                      ? installerContact.userName
                                      : ""}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                  <Typography
                                    variant="caption"
                                    fontWeight={500}
                                    component="span"
                                  >
                                    Installer Contact Phone Number:
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                  <Typography
                                    variant="caption"
                                    component="span"
                                  >
                                    {installerContact &&
                                    installerContact !== null
                                      ? installerContact.phoneNumber
                                      : ""}
                                  </Typography>
                                </Grid>
                              </>
                            ) : null}
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                              <Typography
                                variant="caption"
                                fontWeight={500}
                                component="span"
                              >
                                Default Switch Part Number:
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                              <Typography variant="caption" component="span">
                                {defSwitch && defSwitch !== null
                                  ? defSwitch?.partNbr
                                  : agreement && agreement !== null
                                  ? agreement?.defaultCoatedSwitch &&
                                    agreement?.defaultCoatedSwitch !== null
                                    ? agreement?.defaultCoatedSwitch?.partNbr
                                    : agreement?.defaultSwitch &&
                                      agreement?.defaultSwitch !== null
                                    ? agreement?.defaultSwitch?.partNbr
                                    : ""
                                  : ""}
                              </Typography>
                            </Grid>
                            {orderType === "Project Pack for Home" ? (
                              <>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                  <Typography
                                    variant="caption"
                                    fontWeight={500}
                                    component="span"
                                  >
                                    Default Controller Price:
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                  <Typography
                                    variant="caption"
                                    component="span"
                                  >
                                    {defaultPrice !== ""
                                      ? company.country.cur === "USD"
                                        ? "$"
                                        : "C$" +
                                          " " +
                                          parseFloat(defaultPrice).toFixed(2) +
                                          " "
                                      : ""}{" "}
                                  </Typography>
                                </Grid>
                              </>
                            ) : null}
                          </>
                        )}
                      </Grid>
                    </Paper>
                    <Paper
                      elevation={0}
                      sx={{
                        border: "1px solid #c4c4c4",
                        padding: "20px",
                        marginTop: "20px",
                      }}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Typography variant="subtitle2" component="span">
                            Takeoff
                          </Typography>
                        </Grid>
                        <>
                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Typography
                              variant="caption"
                              fontWeight={500}
                              component="span"
                            >
                              Community:
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Typography variant="caption" component="span">
                              {/* {takeoff && takeoff !== null
                                ? takeoff.community
                                : ""} */}
                              {community ?? ""}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Typography
                              variant="caption"
                              fontWeight={500}
                              component="span"
                            >
                              Plan Number:
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Typography variant="caption" component="span">
                              {takeoff && takeoff !== null
                                ? takeoff.planNum
                                : ""}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Typography
                              variant="caption"
                              fontWeight={500}
                              component="span"
                            >
                              Plan Name:
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Typography variant="caption" component="span">
                              {takeoff && takeoff !== null
                                ? takeoff.planName
                                  ? takeoff.planName
                                  : ""
                                : ""}
                            </Typography>
                          </Grid>
                        </>
                      </Grid>
                    </Paper>
                  </>
                ) : null}

                {isRMA === true ? (
                  <Paper
                    elevation={0}
                    sx={{
                      border: "1px solid #c4c4c4",
                      padding: "20px",
                      marginTop: "20px",
                    }}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="subtitle2" component="span">
                          RMA Details
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography
                          variant="caption"
                          fontWeight={500}
                          component="span"
                        >
                          RMA ID:
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography variant="caption" component="span">
                          {order && order !== null ? order.orderID : ""}
                        </Typography>
                      </Grid>

                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography
                          variant="caption"
                          fontWeight={500}
                          component="span"
                        >
                          Sales Force Ticket Number:
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography variant="caption" component="span">
                          {order && order !== null
                            ? order.purchaseOrderNbr
                            : ""}
                        </Typography>
                      </Grid>

                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography
                          variant="caption"
                          fontWeight={500}
                          component="span"
                        >
                          Created by:
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography variant="caption" component="span">
                          {order && order !== null
                            ? order.createdBy.userName
                            : ""}
                        </Typography>
                      </Grid>

                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography
                          variant="caption"
                          fontWeight={500}
                          component="span"
                        >
                          Creation Date:
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography variant="caption" component="span">
                          {order && order !== null
                            ? convertUTC(order.creationDate)
                            : ""}
                        </Typography>
                      </Grid>

                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography
                          variant="caption"
                          fontWeight={500}
                          component="span"
                        >
                          Modified by:
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography variant="caption" component="span">
                          {order && order !== null
                            ? order.modifiedBy
                              ? order.modifiedBy.userName
                              : ""
                            : ""}
                        </Typography>
                      </Grid>

                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography
                          variant="caption"
                          fontWeight={500}
                          component="span"
                        >
                          Modification Date:
                        </Typography>
                      </Grid>

                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography variant="caption" component="span">
                          {order && order !== null
                            ? convertUTC(order.modificationDate)
                            : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                ) : null}

                {(orderType === "Project Pack for Home" ||
                  orderType === "Bulk Inventory") &&
                isRMA === false ? (
                  <Paper
                    elevation={0}
                    sx={{
                      border: "1px solid #c4c4c4",
                      padding: "20px",
                      marginTop: "20px",
                    }}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="subtitle2" component="span">
                          Total Number of
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography
                          variant="caption"
                          fontWeight={500}
                          component="span"
                        >
                          Switches:
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography variant="caption" component="span">
                          {order && order !== null ? order.switchesNbr : ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography
                          variant="caption"
                          fontWeight={500}
                          component="span"
                        >
                          Quick Mount Switches:
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography variant="caption" component="span">
                          {order && order !== null
                            ? order.quickMountSwitchesNbr
                            : ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography
                          variant="caption"
                          fontWeight={500}
                          component="span"
                        >
                          Switch Plate Positions:
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography variant="caption" component="span">
                          {order && order !== null
                            ? order.switchPlatePositionsNbr
                            : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                ) : null}
              </Grid>
            </Grid>
            {saveError !== "" ? (
              <AlertSnackBar
                message={saveError}
                handleClose={handleCloseAlertSnackbar}
                open={saveError !== ""}
              />
            ) : null}
            {shipModalOpen ? (
              <CustomModal openModal={shipModalOpen} width="70vw">
                <ShippingAddressForm
                  companyID={company?.companyID}
                  fromOrder={true}
                  orderID={receivedOrderID}
                  setFormChanged={setFormChanged}
                  setShipModalOpen={setShipModalOpen}
                  fetchShippingAddressesForOrder={fetchShippingAddresses}
                  setShipToAddress={setShipToAddress}
                  setToUpdateShipAddID={setToUpdateShipAddID}
                  companyName={company ? company.name : ""}
                />
              </CustomModal>
            ) : null}
            {shipModalUpdatelOpen ? (
              <CustomModal openModal={shipModalUpdatelOpen} width="70vw">
                <ShippingAddressForm
                  create={false}
                  companyID={company?.companyID}
                  fromOrder={true}
                  setFormChanged={setFormChanged}
                  setShipModalUpdateOpen={setShipModalUpdateOpen}
                  fetchShippingAddressesForOrder={fetchShippingAddresses}
                  shippingAddressID={toUpdateShipAddID}
                  setShipToAddress={setShipToAddress}
                  companyName={company ? company.name : ""}
                />
              </CustomModal>
            ) : null}
            {shelfInventoryWarning && isRMA === false ? (
              <AlertDialogSlide
                title="Reminder"
                textContent=" Bulk Inventory Orders use list pricing for Levven power controllers. Controllers in this order may have a higher price than Quick Kit Orders."
                save={() => setShelfInventoryWarning(false)}
                saveText="Ok"
              />
            ) : null}
            {dateAlertDialogOpen2 ? (
              <AlertDialogSlide
                title="Warning"
                textContent={
                  <>
                    <Typography
                      variant="body1"
                      component="span"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      The minumum value for Requested Delivery Date is
                      {" " +
                        orderFulfillmentThreshold +
                        " day" +
                        (orderFulfillmentThreshold === 1 ? "" : "s")}{" "}
                      from today. Please adjust your Requested Delivery Date to
                      continue.
                    </Typography>
                  </>
                }
                save={() => {
                  setDateAlertDialogOpen2(false);
                }}
                saveText="Close"
              />
            ) : null}
            {melissaResults.length !== 0 ? (
              <CustomModal openModal={melissaResults.length !== 0} width="60vw">
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h6" component="span">
                      Address Verification
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {melissaResults.map((description) => (
                      <>
                        <Typography variant="body1" component="span">
                          - {description}
                        </Typography>
                        <br />
                      </>
                    ))}
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="body1" component="span">
                      <b>Address Found</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="body1" component="span">
                          <strong>Address: </strong> {instStreet}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="body1" component="span">
                          <strong>Address 2: </strong> {instStreet2}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="body1" component="span">
                          <strong>City: </strong> {instCity}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="body1" component="span">
                          <strong>State/Province: </strong>{" "}
                          {instStateProvince?.description}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="body1" component="span">
                          <strong>Country: </strong> {instCountry.description}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="body1" component="span">
                          <strong>Zip/Post Code: </strong> {instZipCode}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "right",
                        overflowX: "auto",
                      }}
                    >
                      <CustomButton
                        sx={{
                          whiteSpace: windowWidth < 700 ? "normal" : "",
                          height: windowWidth < 700 ? "80px" : "",
                        }}
                        className={classes.customButtonStyle}
                        toggle={() => {
                          setMelissaResults([]);
                          setAddressChanged(true);
                          setAddressVerificationLevel("");
                        }}
                      >
                        Edit Installation Address
                      </CustomButton>

                      <div style={{ width: "10px" }} />
                      <CustomButton
                        toggle={async () => {
                          save("", submitOrderAfterMelissa, true);
                        }}
                        loading={buttonLoading}
                      >
                        Proceed
                      </CustomButton>
                    </div>
                  </Grid>
                </Grid>
              </CustomModal>
            ) : null}
          </>
        )}
        <InfoSnackbar
          open={melissaInfoSnackbar && melissaCreditsDialog === false}
          message={melissaMessageSnackbar}
          severity={melissaSeveritySnackbar}
          handleClose={handleSnackBarClose}
        />
      </Paper>
    );
  }
);

export default OrderForm;
