import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  TextField,
  Typography,
  Tabs,
  Autocomplete,
  Tab,
  Box,
} from "@mui/material";
import { Paper } from "@material-ui/core";
import { useEffect } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import CustomButton from "../CustomButton/CustomButton";
import { Copy, Edit, Plus, Save, Trash2, X } from "react-feather";
import AlertDialogSlide from "../AlertDialogSlide/AlertDialogSlide";
import SnackBar from "../SnackBar/SnackBar";
import { takeoffServices } from "../../services/takeoff.services";
import TakeoffForm from "../TakeoffForm/TakeoffForm";
import TakeoffQuantitiesTable from "../TakeoffQuantitiesTable/TakeoffQuantitiesTable";
import { takeoffQuantityServices } from "../../services/takeoffquantity.service";
import { makeStyles } from "@material-ui/core/styles";
import useUnsavedChangesWarning from "../../hooks/useUnsavedChangesWarning";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";
import { decryptObject } from "../../helpers/link-variables";
import { FrontEndErrors } from "../../helpers/error-messages";
import ConfirmationPopup from "../ConfirmationPopup/ConfirmationTabs";
import { agreementServices } from "../../services/agreement.services";
import CustomModal from "../CustomModal/CustomModal";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 5, pb: 5 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  divStyle: {
    display: "flex",
    textAlign: "right",
    justifyContent: "flex-end",

    "@media (max-width: 1000px)": {
      display: "block",
    },
  },

  divStyle4: {
    width: "10px",
    height: "",
    "@media(max-width: 1000px)": {
      width: "",
      height: "10px",
    },
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TakeoffTabs({ setPageID, setPageLink }) {
  const history = useHistory();
  const location = useLocation();

  //we get data from url
  const params = new URLSearchParams(window.location.search);
  const dataObject = params.get("otbd");
  var decryptedObject = null;
  var fromURL = false;

  if (dataObject !== undefined && dataObject !== null) {
    fromURL = true;
    const returnedObject = decryptObject(dataObject);
    if (returnedObject.error === true)
      history.push({
        pathname: "/dashboard",
        state: {
          redirectionError: FrontEndErrors["frontend_error1"],
        },
      });
    else decryptedObject = returnedObject.returnObject;
  }

  const classes = useStyles();

  /**Fetching data needed from Redux */
  const takeoffRole = useSelector((state) =>
    state.user.user ? state.user.user.user.role.takeoff : ""
  );

  const orderPermission = useSelector((state) =>
    state.user.user ? state.user.user.user.role.orders : ""
  );

  /*Get company ID from redux*/
  const currentCompanyID = useSelector((state) =>
    state.user.user ? state.user.user.user.company.companyID : ""
  );
  /**Getting data sent from previous state */
  // const takeoffID = fromURL
  //   ? decryptedObject
  //     ? decryptedObject.id
  //       ? decryptedObject.id
  //       : 0
  //     : 0
  //   : location.state
  //   ? location.state.id
  //     ? location.state.id
  //     : 0
  //   : 0;
  const [takeoffID, setTakeoffID] = useState(
    fromURL
      ? decryptedObject
        ? decryptedObject.id
          ? decryptedObject.id
          : 0
        : 0
      : location.state
      ? location.state.id
        ? location.state.id
        : 0
      : 0
  );
  const [takeoffName, setTakeoffName] = useState(
    fromURL
      ? decryptedObject
        ? decryptedObject.name
          ? decryptedObject.name
          : ""
        : ""
      : location.state
      ? location.state.name
        ? location.state.name
        : ""
      : ""
  );
  const [takeoffData, setTakeoffData] = useState(null);
  if (takeoffID === 0 && takeoffName === "") {
    history.push({
      pathname: "/dashboard",
      state: {
        redirectionError: FrontEndErrors["frontend_error1"],
      },
    });
  }

  //redirection
  if (takeoffRole === "N") {
    history.push("/Dashboard");
  }

  //change tab we are in
  const [value, setValue] = useState(0);

  //for permissions
  const [adminManage, setAdminManage] = useState(false);

  //make delete button load until deletion process is complete
  const [loading, setLoading] = useState(false);
  const [unlinkButtonLoading, setUnlinkButtonLoading] = React.useState(false);
  const [agreements, setAgreements] = useState([]);
  const [agreement, setAgreement] = useState(null);
  const [agreementError, setAgreementError] = useState(false);
  const [refreshFromTabs, setRefreshFromTabs] = useState(false);

  //holds error encountered during the deletion process
  const [deleteError, setDeleteError] = useState("");

  //displays the alert dialog before deletion
  const [deleteRecord, setDeleteRecord] = useState(false);

  //holds if any change happened
  const [formChanged, setFormChanged] = useState(false);

  /**Holds Number of switches */
  const [switchesNum, setSwitchesNum] = React.useState(0);

  /**In case of error */
  const [takeoffError, setTakeoffError] = React.useState("");

  /**Holds number of plate positions (wall plates) */
  const [platePositionNum, setPlatePositionNum] = React.useState(0);

  /**true if there is quick mount */
  const [isQuickMount, setIsQuickMount] = React.useState(false);

  /**Holds rows to display */
  const [rows, setRows] = React.useState([]);
  const [linkedTakeOffs, setLinkedTakeOffs] = React.useState([]);

  /**For loading of button */
  const [buttonLoading, setButtonLoading] = React.useState(false);

  /**displays error if quantities are not equal */
  const [notEqualQuantities, setNotEqualQuantities] = React.useState("");

  /**Loading */
  const [newOrderLoading, setNewOrderLoading] = React.useState(false);

  /**In case of unsaved changes */
  const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();
  const [saveError, setSaveError] = useState("");

  /**Getting data from previous page */
  const fromDashboard = fromURL
    ? decryptedObject
      ? decryptedObject.fromDashboard
        ? decryptedObject.fromDashboard
        : false
      : false
    : location.state
    ? location.state.fromDashboard
      ? location.state.fromDashboard
      : false
    : false;

  //we get state from previous page

  const doesTakeoff = fromURL
    ? decryptedObject
      ? decryptedObject.doesTakeoff
        ? decryptedObject.doesTakeoff
        : false
      : false
    : location.state
    ? location.state.doesTakeoff
      ? location.state.doesTakeoff
      : false
    : false;

  //to redirect to a certain tab when coming from previous page
  const tab = location.state
    ? location.state.tab
      ? location.state.tab
      : null
    : null;

  useEffect(() => {
    if (tab !== null) setValue(tab);
  }, []);

  const takeoffRef = useRef(null);
  /*SnackBar open handling*/
  const [openSnackBar, setOpenSnackBar] = useState(
    location.state?.openSnackBar ? true : false
  );

  const [openUnlinkSnackBar, setOpenUnlinkSnackBar] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openUnlinkDialog, setOpenUnlinkDialog] = useState(false);
  const [tempVal, setTempVal] = useState(0);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showAgreementDropdown, setShowAgreementDropdown] = useState(false);
  const [duplicatedFromID, setduplicatedFromID] = useState(null);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    if (showDropdown && buttonRef.current && dropdownRef.current) {
      // Get button's position
      const buttonRect = buttonRef.current.getBoundingClientRect();

      // Set dropdown's left position based on button's left
      dropdownRef.current.style.left = `${buttonRect.left}px`;
      dropdownRef.current.style.top = `${buttonRect.bottom}px`; // Position it just below the button
    }
  }, [showDropdown]);
  const handleChange = (event, newValue) => {
    //change tab we are in
    if (formChanged) {
      setOpenDialog(true);
      setTempVal(newValue);
    } else {
      setValue(newValue);
    }
  };

  const handleRouteClick = (route) => {
    if (formChanged) {
      setOpenDialog(true);
      setTempVal(route);
    } else {
      history.push(route);
    }
  };

  const handleConfirm = () => {
    setValue(tempVal);
    setFormChanged(false);
    setOpenDialog(false);
    if (tempVal === "/create-order") {
      goToOrder();
    } else history.push(tempVal);

    sessionStorage.removeItem("unsavedChanges");
  };

  const handleDuplicateWithoutLink = () => {
    setShowDropdown(false);
    getAllAgreements();
  };

  const handleDuplicateWithLink = () => {
    setduplicatedFromID(takeoffID);
    setShowDropdown(false);
    getAllAgreements();
  };
  const handleClose = () => {
    setOpenDialog(false);
  };
  const handleUnlinkTakeoffsClose = () => {
    setOpenUnlinkDialog(false);
  };

  const saveQuantities = () => {
    /**Function to save Quantities */
    setButtonLoading(true);

    if (isQuickMount === true && switchesNum !== platePositionNum) {
      setNotEqualQuantities(
        "The number of Switches (" +
          switchesNum +
          ") is not equal to the Switch Plate Positions (" +
          platePositionNum +
          ")"
      );
      setButtonLoading(false);
    } else {
      takeoffQuantityServices
        .updateExistingTakeoffQuantities({
          TakeoffID: takeoffID,
          ProductQuantities: rows,
          SwitchesNum: switchesNum,
          SwitchPlatePositionsNum: platePositionNum,
          UpdateLinkedTakeoffs: true,
          LastModifiedDate: takeoffData.modificationDate,
        })
        .then((response) => {
          setOpenSnackBar(true);
          setPristine();
          setFormChanged(false);
          takeoffServices
            .getTakeoffByID(takeoffID)
            .then((response) => {
              setTakeoffData(response.data);
            })
            .catch((error) => {
              setTakeoffError(error.response.data.error.error);
              setNewOrderLoading(false);
            });
          sessionStorage.removeItem("unsavedChanges");
        })
        .catch((error) => {
          setNotEqualQuantities(error.response.data.error.error);
        })
        .finally(() => {
          setButtonLoading(false);
        });
    }
  };

  useEffect(() => {
    //for help button
    setPageLink("Takeoffs");

    //for drawer menu select
    if (!fromDashboard) setPageID(4);
    else setPageID(0);
  }, []);
  const handleUnlinkConfirm = () => {
    /**Function to go to Order */
    setUnlinkButtonLoading(true);

    takeoffServices
      .updateTakeoffLinkStatus({
        takeoffID: takeoffID,
        unlink: true,
      })
      .then((response) => {
        setUnlinkButtonLoading(false);
        handleUnlinkTakeoffsClose();
        setOpenUnlinkSnackBar(true);

        setRefreshFromTabs(true);
      })
      .catch((error) => {
        setUnlinkButtonLoading(false);
        handleUnlinkTakeoffsClose();
        setTakeoffError(error.response.data.error.error);
      });
  };
  const deleteTakeoffs = () => {
    //function to delete Takeoff Value
    setLoading(true);

    setDeleteRecord(false);
    takeoffServices
      .deleteTakeoff({ TakeoffList: [takeoffID] })
      .then(() => {
        setLoading(false);
        history.push({
          pathname: "/Takeoff",
          state: {
            success: true,
          },
        });
      })
      .catch((error) => {
        setLoading(false);
        setDeleteError(error.response.data.error.error);
      });
  };

  /*Launch Wizard*/
  const toWizard = () => {
    history.push({
      pathname: "/takeoff-product-quantities",
      state: {
        create: false,
        agreementID: location.state.agreementID,
        takeoffID: takeoffID,
        takeoffName: takeoffData.name,
        LastModifiedDate: takeoffData.modificationDate,
      },
    });
  };

  /*Handle closing snackBar*/
  const handleSnackBarClose = (event, reason) => {
    setOpenSnackBar(false);
    setOpenUnlinkSnackBar(false);
  };
  const handleUnlinkSnackBarClose = (event, reason) => {
    setOpenUnlinkSnackBar(false);
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (takeoffRole === "M" && doesTakeoff === true) {
      setAdminManage(true);
    }
  }, []);
  /*Get Agreements*/
  const getAllAgreements = () => {
    agreementServices
      .getAllAgreements(currentCompanyID)
      .then((response) => {
        setAgreements(response.data);
        setShowAgreementDropdown(true);
      })
      .catch((error) => setSaveError(error.response.data.error.error));
  };
  const goToOrder = async () => {
    /**Function to go to Order */
    setNewOrderLoading(true);

    await takeoffServices
      .getTakeoffByID(takeoffID)
      .then((response) => {
        setNewOrderLoading(false);
        history.push({
          pathname: "/create-order",
          state: {
            takeoff: response.data,
            fromTakeoff: true,
          },
        });
      })
      .catch((error) => {
        setTakeoffError(error.response.data.error.error);
        setNewOrderLoading(false);
      });
  };

  /*Handle Close Alert Snackbar*/
  const handleCloseAlertSnackbar = (event, reason) => {
    setTakeoffError("");
  };
  const duplicateTakeoff = (duplicatedFromID, selectedAgreement) => {
    takeoffServices
      .createTakeoff({
        // Name: takeoffName,
        Name: `${selectedAgreement.builder.name} ${
          takeoffData.community !== "" && takeoffData.community !== "N/A" && takeoffData.community !== null ? takeoffData.community + " " : ""
        }${takeoffData.planName !== "" && takeoffData.planName != null && takeoffData.planName != "N/A" ? takeoffData.planName + " " : ""}${
          takeoffData.planNum
        }`,
        AgreementID: selectedAgreement.agreementID,
        Community: takeoffData.community === "" ? "N/A" : takeoffData.community,
        PlanNum: takeoffData.planNum,
        PlanName: takeoffData.planName,
        Area: takeoffData.area,
        AreaUOM: takeoffData.areaUnit === "Square Feet" ? "ft2" : "m2",
        BathroomSwitchesNum: takeoffData.bathroomSwitchesNum,
        BathroomNum: takeoffData.bathroomNum,
        CreatedByID: takeoffData.createdByID,
        IsALowVoltageCabinetStandardInThisPlan:
          takeoffData.isALowVoltageCabinetStandardInThisPlan,
        DuplicatedFromID: duplicatedFromID,
      })
      .then((response) => {
        setPristine();
        setFormChanged(false);
        sessionStorage.removeItem("unsavedChanges");

        setOpenSnackBar(true);
        // setSaveError("");
        const result = response.data;
        setTakeoffID(result.takeoffID);
        history.push({
          pathname: "/takeoff-information",
          state: {
            id: result.takeoffID,
            name: result.name,
            agreementID: selectedAgreement.agreementID,
            openSnackBar: false,
            doesTakeoff: true,
          },
        });

        setRefreshFromTabs(true);
      })
      .catch((error) => {
        setSaveError(error.response?.data.message);
      })
      .finally(() => {
        setShowDropdown(false);
        setShowAgreementDropdown(false);
        setButtonLoading(false);
        setAgreement(null);
      });
  };
  return (
    <>
      <ConfirmationPopup
        openDialog={openDialog}
        handleClose={handleClose}
        handleConfirm={() => handleConfirm(value)}
        title="Leave Page?"
        message="You have unsaved changes. Are you sure you want to leave?"
        confirmLabel="Leave"
        cancelLabel="Cancel"
      />
      {openUnlinkDialog ? (
        linkedTakeOffs.length > 0 ? (
          <AlertDialogSlide
            title="Unlink Takeoff"
            textContent={"Are you sure you want to unlink takeoffs?"}
            save={handleUnlinkConfirm}
            saveText="Yes"
            deleteDialog={true}
            unsave={handleUnlinkTakeoffsClose}
            unsaveText="No"
          />
        ) : (
          <></>
          // <AlertDialogSlide
          //   title="Unlink Takeoff"
          //   textContent={
          //     "The takeoff is already not linked to any other takeoffs."
          //   }
          //   unsave={handleUnlinkTakeoffsClose}
          //   unsaveText="Ok"
          // />
        )
      ) : (
        ""
      )}

      {/* {Prompt} */}
      {openSnackBar ? (
        <SnackBar
          open={openSnackBar}
          message="Saved Successfully"
          severity="success"
          handleClose={handleSnackBarClose}
        />
      ) : null}
      {openUnlinkSnackBar ? (
        <SnackBar
          open={openUnlinkSnackBar}
          message="Unlink Successfully"
          severity="success"
          handleClose={handleUnlinkSnackBarClose}
        />
      ) : null}
      <Paper>
        {deleteRecord ? (
          <AlertDialogSlide
            title="Takeoff Deletion Error"
            textContent={"Are you sure you want delete this record?"}
            save={deleteTakeoffs}
            saveText="Yes"
            deleteDialog={true}
            unsave={() => setDeleteRecord(false)}
            unsaveText="No"
          />
        ) : (
          ""
        )}
        {takeoffError !== "" ? (
          <AlertSnackBar
            message={takeoffError}
            handleClose={handleCloseAlertSnackbar}
            open={takeoffError !== ""}
          />
        ) : null}
        {deleteError ? (
          <AlertSnackBar
            message={deleteError}
            handleClose={(event, reason) => {
              if (reason !== "clickaway") setDeleteError("");
            }}
            open={deleteError !== ""}
          />
        ) : null}

        {notEqualQuantities ? (
          <AlertSnackBar
            message={notEqualQuantities}
            handleClose={(event, reason) => {
              if (reason !== "clickaway") setNotEqualQuantities("");
            }}
            open={notEqualQuantities !== ""}
          />
        ) : null}
        <Box
          sx={{
            width: "100%",
            paddingTop: "30px",
            paddingLeft: "30px",
            paddingRight: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              overflow: "auto",
            }}
          >
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              {fromDashboard ? (
                <Link
                  underline="always"
                  key="0"
                  color="inherit"
                  onClick={() =>
                    history.push({
                      pathname: "/Dashboard",
                    })
                  }
                  sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
                >
                  Dashboard
                </Link>
              ) : null}
              <Link
                underline="always"
                key="1"
                color="inherit"
                onClick={() => history.push("/Takeoff")}
                sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
              >
                Takeoffs
              </Link>

              {value === 1 ? (
                <Link
                  underline="always"
                  key="2"
                  color="inherit"
                  onClick={() => {
                    if (formChanged) {
                      handleConfirm();
                    } else {
                      setValue(0);
                    }
                  }}
                  sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
                >
                  {takeoffName}
                  {/* {takeoffData != null ? takeoffData.name : takeoffName} */}
                </Link>
              ) : (
                <Typography
                  key="2"
                  color="text.primary"
                  sx={{ fontSize: "24px" }}
                >
                  {takeoffName}
                  {/* {takeoffData != null ? takeoffData.name : takeoffName} */}
                </Typography>
              )}
              {value === 1 ? (
                <Typography
                  key="3"
                  color="text.primary"
                  sx={{ fontSize: "24px" }}
                >
                  Products/Quantities
                </Typography>
              ) : (
                ""
              )}
            </Breadcrumbs>

            <div style={{ display: "flex", flexDirection: "row" }}>
              {orderPermission === "M" && value === 0 ? (
                <CustomButton
                  extraMarginLeft={"10px"}
                  startIcon={<Plus />}
                  toggle={() => handleRouteClick("/create-order")}
                >
                  New Order
                </CustomButton>
              ) : null}
              {orderPermission === "M" && value === 0 ? (
                <>
                  <div style={{ width: "10px" }} />
                  <CustomButton
                    extraMarginLeft={"10px"}
                    startIcon={<X />}
                    loading={unlinkButtonLoading}
                    toggle={() => setOpenUnlinkDialog(true)}
                    disable={linkedTakeOffs.length == 0}
                  >
                    Unlink Takeoff
                  </CustomButton>{" "}
                </>
              ) : null}
              {takeoffRole === "M" ? (
                <>
                  {value === 0 ? (
                    <>
                      <div style={{ width: "10px" }} />
                      <div style={{ position: "relative" }}>
                        <CustomButton
                          startIcon={<Copy />}
                          ref={buttonRef}
                          toggle={() => setShowDropdown(!showDropdown)}
                        >
                          Duplicate
                        </CustomButton>
                        {showDropdown && (
                          <div
                            ref={dropdownRef} // Attach the ref to dropdown
                            style={{
                              position: "fixed",
                              backgroundColor: "white",
                              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
                              borderRadius: "8px",
                              zIndex: 1000,
                              padding: "20px",
                              width: "300px",
                            }}
                          >
                            <div
                              style={{ padding: "8px", cursor: "pointer" }}
                              onClick={handleDuplicateWithoutLink}
                              onMouseEnter={(e) =>
                                (e.currentTarget.style.backgroundColor =
                                  "#e0f7fa")
                              } // Light blue hover effect
                              onMouseLeave={(e) =>
                                (e.currentTarget.style.backgroundColor =
                                  "transparent")
                              } // Reset background
                            >
                              Duplicate takeoff without link
                            </div>
                            <div
                              style={{ padding: "8px", cursor: "pointer" }}
                              onClick={handleDuplicateWithLink}
                              onMouseEnter={(e) =>
                                (e.currentTarget.style.backgroundColor =
                                  "#e0f7fa")
                              } // Light blue hover effect
                              onMouseLeave={(e) =>
                                (e.currentTarget.style.backgroundColor =
                                  "transparent")
                              } // Reset background
                            >
                              Duplicate takeoff and quantities with link
                            </div>
                          </div>
                        )}
                        {showAgreementDropdown && (
                          <CustomModal openModal={showAgreementDropdown}>
                            {/* Close button */}
                            <button
                              style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                backgroundColor: "transparent",
                                border: "none",
                                fontSize: "16px",
                                cursor: "pointer",
                              }}
                              onClick={() => setShowAgreementDropdown(false)}
                            >
                              X
                            </button>
                            <Typography variant="h6" component="span">
                              Choose an agreement
                            </Typography>
                            <br />
                            <br />

                            {/* Adjust space between the X button and dropdown */}
                            <div style={{ marginTop: "20px" }}>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <Autocomplete
                                  id="combo-box-demo1"
                                  options={agreements}
                                  getOptionLabel={(option) =>
                                    option.name ? option.name : ""
                                  }
                                  value={agreement ? agreement : {}}
                                  onChange={(event, value) => {
                                    setAgreement(value);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Agreement"
                                      fullWidth
                                      size="small"
                                      required
                                      error={agreementError}
                                      helperText={
                                        agreementError
                                          ? "Agreement field is empty"
                                          : ""
                                      }
                                    />
                                  )}
                                />
                              </Grid>
                            </div>

                            {/* Proceed button */}
                            <div
                              style={{ marginTop: "20px", textAlign: "center" }}
                            >
                              {" "}
                              <CustomButton
                                toggle={() =>
                                  duplicateTakeoff(duplicatedFromID, agreement)
                                }
                                disable={agreement == null}
                              >
                                Proceed
                              </CustomButton>
                              <br />
                              <br />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "right",
                                }}
                              ></div>
                            </div>
                          </CustomModal>
                        )}
                      </div>
                    </>
                  ) : null}

                  {value === 1 ? (
                    <>
                      <div style={{ width: "10px" }} />
                      <CustomButton
                        startIcon={<Save />}
                        loading={buttonLoading}
                        toggle={saveQuantities}
                      >
                        Save
                      </CustomButton>
                    </>
                  ) : null}

                  <div style={{ width: "10px" }} />
                  <CustomButton startIcon={<Edit />} toggle={toWizard}>
                    Edit Takeoff
                  </CustomButton>
                  {value === 0 ? (
                    <>
                      {" "}
                      <div style={{ width: "10px" }} />
                      <CustomButton
                        startIcon={<Trash2 />}
                        loading={loading}
                        toggle={() => setDeleteRecord(true)}
                      >
                        Delete
                      </CustomButton>
                    </>
                  ) : null}
                </>
              ) : null}

              <div style={{ width: "10px" }} />
              <CustomButton
                startIcon={<X />}
                toggle={async () => {
                  if (value === 0) await takeoffRef.current.setPristine();
                  else await setPristine();

                  await history.push("/takeoff");
                }}
              >
                Cancel
              </CustomButton>
            </div>
          </div>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              marginTop: "40px",
            }}
          >
            <Tabs
              variant={"scrollable"}
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="Information"
                {...a11yProps(0)}
                style={{ margin: "50" }}
              />

              <Tab
                label="Products/Quantities"
                {...a11yProps(1)}
                style={{ margin: "50" }}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <TakeoffForm
              adminManage={adminManage}
              receivedTakeoffID={takeoffID}
              setTakeoffNameForTabs={setTakeoffName}
              setTakeoffData={setTakeoffData}
              ref={takeoffRef}
              setFormChanged={setFormChanged}
              refreshFromTabs={refreshFromTabs}
              linkedTakeOffs={linkedTakeOffs}
              setLinkedTakeOffs={setLinkedTakeOffs}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Grid container spacing={2} style={{ marginBottom: "30px" }}>
              <Grid item sm={12} md={6} lg={4}>
                <Typography>Total Number of Switches: {switchesNum}</Typography>
              </Grid>
              <Grid item sm={12} md={6} lg={4}>
                <Typography>
                  Total Number of Switch Plate Positions: {platePositionNum}
                </Typography>
              </Grid>
            </Grid>

            <TakeoffQuantitiesTable
              takeoffID={takeoffID}
              setFormChanged={setFormChanged}
              switchesNum={switchesNum}
              setDirty={setDirty}
              setPristine={setPristine}
              setSwitchesNum={setSwitchesNum}
              platePositionNum={platePositionNum}
              setPlatePositionNum={setPlatePositionNum}
              setIsQuickMount={setIsQuickMount}
              rows={rows}
              setRows={setRows}
            />
            <div style={{ height: "20px" }} />
            <div className={classes.divStyle}>
              {takeoffRole === "M" ? (
                <CustomButton
                  startIcon={<Save />}
                  loading={buttonLoading}
                  toggle={saveQuantities}
                >
                  Save
                </CustomButton>
              ) : null}
              <div className={classes.divStyle4} />
              <CustomButton
                startIcon={<X />}
                toggle={async () => {
                  if (value === 0) await takeoffRef.current.setPristine();
                  else await setPristine();

                  await history.push("/takeoff");
                }}
              >
                Cancel
              </CustomButton>
            </div>
          </TabPanel>
        </Box>
      </Paper>
    </>
  );
}
